//@ts-nocheck
import * as THREE from 'three';
import TWEEN from '@tweenjs/tween.js';

import { GUI } from 'three/examples/jsm/libs/dat.gui.module.js'
import Stats from 'three/examples/jsm/libs/stats.module.js'
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';
import { OutlinePass } from 'three/examples/jsm/postprocessing/OutlinePass.js';
import { FXAAShader } from 'three/examples/jsm/shaders/FXAAShader.js';
//import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js';

import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';

// assets

import hdr from "../../public/assets/hdr/driving_school_1k.hdr";

import geniox1 from "../../public/assets/Genoix 1/Geniox_Big-Expo_512_PBR.glb";
import geniox2 from "../../public/assets/Genoix 2/Geniox_Small-Expo_512_PBR.glb";
import sysaqua1 from "../../public/assets/Sysaqua/Sysaqua-Expo_512_PBR.glb";


import * as C from "./constants";
import * as T from "@/types/index";
import PAVILLION_PARTS from "./pavillion_parts";

import UNITS_PLACEMENT from "./pavillion_units";

window.THREE = THREE;

// state store
import store from "@/store";
import { Euler } from 'three';

// scene settings
let container;
let stats: Stats | null = null;
let camera: THREE.Camera | null = null;
let scene: THREE.Scene | null = null;
let controls: OrbitControls | null = null;
let renderer: THREE.Renderer | null = null;
let composer: THREE.Composer | null = null;
let outlinePass: OutlinePass | null = null;
let outlinePassSelected: OutlinePass | null = null;
let effectFXAA: ShaderPass | null = null;


let cloudPlane, cloudPlane2, cloudPlane3, cloudPlane4
let shadowplane

// Array for assets that should be billboarding
let billboardAssets = []

let mouse = THREE.Vector2;
let raycaster = new THREE.Raycaster();
let intersects = null;

let windowHalfX = window.innerWidth / 2;
let windowHalfY = window.innerHeight / 2;

// floor interaction

let currentFloor = C.INITIAL_FLOOR;	

let activeTweens : Array<TWEEN>  = [];

// models
let pavillionMdl : Three.Mesh | null = null;
let genioxMdl : Three.Mesh | null = null;
let genioxMdl2 : Three.Mesh | null = null;
let sysaquaMdl : Three.Mesh | null = null;

// dev stuff
let devTransaprentMaterial = new THREE.MeshBasicMaterial( { color: "#97c4c4", opacity: 0.5, transparent: true } );
let shouldUseOrtoCamera = { orto: false};
let cameraOrto: THREE.Camera = new THREE.OrthographicCamera( 2000,  -2000, 2000, -2000, 1, 10000 );
//let cameraPersp: THREE.PerspectiveCamera = new THREE.PerspectiveCamera( 20, window.innerWidth / window.innerHeight, C.CAMERA_NEAR, C.CAMERA_FAR );

let envMap

const DEBUG_MODE = window.location.search === "?debug";

//Tone mapping params and options
const toneMappingParams = {
	exposure: 2.1,
	toneMapping: 'ACESFilmic'
};

const toneMappingOptions = {
	None: THREE.NoToneMapping,
	Linear: THREE.LinearToneMapping,
	Reinhard: THREE.ReinhardToneMapping,
	Cineon: THREE.CineonToneMapping,
	ACESFilmic: THREE.ACESFilmicToneMapping,
	Custom: THREE.CustomToneMapping
};

export function init(CanvasContainer: HTMLElement) 
{
	container = CanvasContainer;
	renderer = new THREE.WebGLRenderer( { antialias: true } );

	// Tone mapping init
	renderer.toneMapping = toneMappingOptions[ toneMappingParams.toneMapping ];
	renderer.toneMappingExposure = toneMappingParams.exposure;
	renderer.outputEncoding = THREE.LinearEncoding ;
	

    // init scene
	scene = new THREE.Scene();
	scene.background = new THREE.Color(0xFFFFFF);
	// const bgloader = new THREE.TextureLoader();
	// const bgTexture = bgloader.load("assets/gradient.jpg",);
	// bgTexture.encoding = THREE.LinearEncoding;
	// scene.background = bgTexture;

	THREE.Cache.enabled = true;

    // camera and controls setting
    camera = new THREE.PerspectiveCamera(window.innerWidth < 500 ? 110 : 55, window.innerWidth / window.innerHeight, C.CAMERA_NEAR, C.CAMERA_FAR);

	camera.position.z = C.START_CAMERA_INIT_POS.z;
	camera.position.x = C.START_CAMERA_INIT_POS.x;
	camera.position.y = C.START_CAMERA_INIT_POS.y;
	
	//camera.position.set(C.START_CAMERA_INIT_POS);
	camera.setRotationFromEuler(new THREE.Euler(C.START_CAMERA_INIT_ROT.x, C.START_CAMERA_INIT_ROT.y, C.START_CAMERA_INIT_ROT.z, C.START_CAMERA_INIT_ROT.order));

	controls = new OrbitControls( camera, renderer.domElement );

    controls.enableDamping = true;
    controls.dampingFactor  = C.DAMPING_FACTOR;  
	controls.enablePan = false;
	// restrain cam not to look below the floor 
    controls.maxPolarAngle = C.MAX_POLAR_ANGLE;
    // restrain cam not to clip through the pavillion
    controls.minDistance = C.MIN_DISTANCE;
    // restrain cam to zoom-out until you can fully see the pavillion
    controls.maxDistance = C.MAX_DISTANCE;
    controls.rotateSpeed = C.ROTATE_SPEED;
    controls.zoomSpeed = C.ZOOM_SPEED;
	controls.target.set(0, 0, 0);

   // init lights
    const light = new THREE.DirectionalLight( 0xffffff );
	light.intensity = 2
	light.position.set( -300, -345, 10 );
	//scene.add( light );
	
	const light2 = new THREE.DirectionalLight( 0xffffff );
	light2.intensity = .2
	light2.position.set( 300, 345, -100 );
	//scene.add( light2 );

	const color = 0xFFFFFF;
	
	const intensity = .5;
	const light1 = new THREE.AmbientLight(color, intensity);
	scene.add(light1);

	const hemisphereLight = new THREE.HemisphereLight(0xffffff, 0x77aff3, 0.1)

	
	//init clouds
	const cloudColor = 0xd9fbff
	// 0x8CDBF8; ccf9ff 0xd9fbff
	const cloudTexture = new THREE.TextureLoader().load( 'assets/cloud10.png' );
	const cloudGeo = new THREE.PlaneGeometry( 3000, 3000 );
	const cloudMat = new THREE.MeshBasicMaterial( { map: cloudTexture,
	color: cloudColor,
	transparent: true,
	opacity:0.4
	} );

	cloudPlane = new THREE.Mesh( cloudGeo, cloudMat );
	cloudPlane2 = cloudPlane.clone();
	cloudPlane3 = cloudPlane.clone();
	cloudPlane4 = cloudPlane.clone();
	cloudPlane.position.set(-2000, 2000, 0)
	cloudPlane2.position.set(2000, 1900, 0)
	cloudPlane3.position.set(500, 1900, 2000)
	cloudPlane4.position.set(0, 4000, 0)
	billboardAssets.push(cloudPlane, cloudPlane2, cloudPlane3, cloudPlane4)
	scene.add( cloudPlane, cloudPlane2, cloudPlane3, cloudPlane4 );
	
    //init shadowplane
	const shadowplaneTexture = new THREE.TextureLoader().load( 'assets/shadowGradient.png' );
	const shadowplaneGeo = new THREE.PlaneGeometry( 5000, 5000 );
	const shadowplaneMat = new THREE.MeshBasicMaterial( { map: shadowplaneTexture,
	transparent:true,
	opacity: 0.1
	} );
	shadowplane = new THREE.Mesh( shadowplaneGeo, shadowplaneMat );
	shadowplane.rotation.x = Math.PI / 2*3;
	scene.add( shadowplane );

	
	new RGBELoader().load(hdr, (texture) => 
	{
		texture.mapping = THREE.EquirectangularReflectionMapping;
		scene.environment = texture;
		envMap = texture;
	});

	const manager = makeLoadingManager();

	const loader = new GLTFLoader( manager );
	  
	const loadingTasks =  [ 
		 { path: sysaqua1, onLoaded: ( gltf ) => {  sysaquaMdl = gltf.scene;  } },
		 { path: geniox1, onLoaded: ( gltf ) => {  genioxMdl = gltf.scene; } },
		 { path: geniox2, onLoaded: ( gltf ) => {  genioxMdl2 = gltf.scene; } }
	];

	Object.values(PAVILLION_PARTS).forEach((part) => 
	{ 
		const { path, initPosition } = part;	
		loadingTasks.push( { path, onLoaded: (glb) => {	
				
				const model = glb.scene;
				
				const { x , y, z } = initPosition;
				model.position.x = x;
				model.position.y = y;
				model.position.z = z;

				model.scale.x = 100;
				model.scale.y = 100;
				model.scale.z = 100;

				part.model = model;

				// add pavillion part to scene
				scene?.add(model);
			}});
	});

	loadingTasks.forEach( ({ path, onLoaded }) => { loader.load( path, onLoaded ) });
	
	
	// postprocessing
	composer = new EffectComposer( renderer );
	composer.setSize( window.innerWidth, window.innerHeight );
	composer.setPixelRatio( window.devicePixelRatio );
	const renderPass = new RenderPass( scene, camera );

	composer.addPass( renderPass );

	outlinePass = new OutlinePass( new THREE.Vector2( window.innerWidth, window.innerHeight ), scene, camera );
	outlinePass.edgeStrength = 15;
	outlinePass.edgeThickness = 1.25;
	outlinePass.edgeGlow  = 0.1;
	outlinePass.pulsePeriod  = 2.5;
	outlinePass.visibleEdgeColor.set('#037BC7');
	outlinePass.hiddenEdgeColor.set('#037BC7');
	outlinePass.selectedObjects = PAVILLION_PARTS[C.INITIAL_FLOOR].units;
	composer.addPass( outlinePass );

	outlinePassSelected = new OutlinePass( new THREE.Vector2( window.innerWidth, window.innerHeight ), scene, camera );
	outlinePassSelected.edgeStrength = 15;
	outlinePassSelected.edgeThickness = 1.25;
	outlinePassSelected.edgeGlow  = 0.1;
	outlinePassSelected.pulsePeriod  = 0;
	outlinePassSelected.visibleEdgeColor.set('#fd6200');
	outlinePassSelected.hiddenEdgeColor.set('#fd6200');
	composer.addPass( outlinePassSelected );

	effectFXAA = new ShaderPass( FXAAShader );
	effectFXAA.uniforms[ 'resolution' ].value.set( 0.2 / window.innerWidth, 0.2 / window.innerHeight );
	composer.addPass( effectFXAA );
	//composer.addPass( bloomPass );

	renderer.setPixelRatio( window.devicePixelRatio );
	renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
	renderer.setSize( window.innerWidth, window.innerHeight );

    container.appendChild( renderer.domElement );
	
	if(DEBUG_MODE) {
		stats = new Stats();
		stats.domElement.style.cssText = 'position:absolute; top:0px; right:0px;';
		container.appendChild( stats.dom );
	}
	
	
	document.addEventListener('touchstart', onTouch );
	document.addEventListener('touchmove', onTouch );
	document.addEventListener('touchend', onTouch );
	document.addEventListener('pointermove', onDocumentMouseMove );
	document.addEventListener('pointerup', onDocumentMouseUp );
	document.addEventListener('floor-selected', onFloorSelected );

	document.addEventListener('panel-closed', deselectUnit );
	//
	window.addEventListener( 'resize', onWindowResize );
}

function makeLoadingManager() {
	const manager = new THREE.LoadingManager();

	manager.onStart = function ( url, itemsLoaded, itemsTotal ) {};
	manager.onLoad = function ( ) 
	{ 
		placeUnits();

		store.commit("loadingDone"); 
		//ga('send', 'pageview', location.pathname);
		doInitAnimation();
	};

	manager.onProgress = function ( url, itemsLoaded, itemsTotal ) {
		store.commit("loadingProgress", (itemsLoaded / itemsTotal) * 100);
	};

	manager.onError = function ( url ) {
		console.error( 'There was an error loading ' + url );
	};
	
	return manager;
}

function placeUnits () 
{
	UNITS_PLACEMENT.forEach((u) => 
	{ 
		const { model, position, rotation, serial, floor } = u;
		let mdl;
		
		if(model.startsWith("Geniox_Big")) {
			mdl = genioxMdl.clone();
		} else if (model.startsWith("Geniox_Small")){
		 	mdl = genioxMdl2.clone();
		} else {
			mdl = sysaquaMdl.clone();
		}
		
		const { x , y, z } = position;
		
		
		mdl.position.x = PAVILLION_PARTS[floor].initPosition.x || x;
		mdl.position.y = PAVILLION_PARTS[floor].initPosition.y || y;
		mdl.position.z = PAVILLION_PARTS[floor].initPosition.z || z;

		mdl.rotation.x = rotation.x;
		mdl.rotation.y = rotation.y;
		mdl.rotation.z = rotation.z;

		mdl.scale.x = C.UNITS_SCALE;
	 	mdl.scale.y = C.UNITS_SCALE;
	 	mdl.scale.z = C.UNITS_SCALE;

		u.sceneObject = mdl; 
		
		mdl.children[0].name = serial;

		// only make units with a serial number highlight
		if(serial) 
		{ 
			mdl.defaultPosition = { x , y , z };
			PAVILLION_PARTS[floor].units.push(mdl);
		}

		// add to scene
		scene?.add(mdl);
	});
}

function doInitAnimation() {

	const fallDuration = 1800;
	const delay = 1500;

	let tweens = Object.entries(PAVILLION_PARTS).map( ( [ floor, obj], i ) => { 
			return floor === T.PAVILLION_FLOORS.WHOLE_PAVILLION_VIEW ? [] :
			 [new TWEEN.Tween({ y: obj.initPosition.y })
				.to({ y: obj.defaultPosition.y }, fallDuration )
				.easing(TWEEN.Easing.Quartic.InOut)
				.delay( i * 2 * delay / 2 )
				.onComplete(() => {
					store.commit("setFloorInUI", floor);
					outlinePass.selectedObjects = PAVILLION_PARTS[floor].units;
					
					if( i === 2 ){
						store.commit("initAnimationDone");
					}
				})
				.onUpdate(({ y }) => {  
					 obj.model.position.y = y;
				})
				.start(),
				...obj.units.map((unit) =>
				 { return new TWEEN.Tween({ y: unit.position.y })
				 .to({ y: unit.defaultPosition.y }, fallDuration )
				.easing(TWEEN.Easing.Quartic.InOut)
				.delay( (i * 2 + .5) * delay / 2 )
				.onUpdate(({ y }) => {  
					 unit.position.y = y;
				})
				.start() 
			})
			];
	} );


	activeTweens = [ ...tweens.flat() ];


}

function onWindowResize() {
	windowHalfX = window.innerWidth / 2;
	windowHalfY = window.innerHeight / 2;
	camera.aspect = window.innerWidth / window.innerHeight;
	camera.updateProjectionMatrix();
	renderer.setSize( window.innerWidth , window.innerHeight );
	composer.setSize( window.innerWidth, window.innerHeight );
}

function selectUnit(object) 
{
	ga('send', {
		hitType: 'pageview',
		page: object.name
	  });
	  
	outlinePassSelected.selectedObjects = [object || intersects[0].object];
	outlinePass.pulsePeriod = 0;
	outlinePass.edgeGlow = 0;
	outlinePass.edgeThickness = 0.005;
	outlinePass.edgeStrength = 15;
}

function deselectUnit() { 
	outlinePassSelected.selectedObjects = [];
	outlinePass.pulsePeriod  = 2.5;
	outlinePass.edgeGlow  = 0.1;
	outlinePass.edgeThickness = 1.25;
	outlinePass.edgeStrength = 15;
 }

function onTouch(event) 
{
	if(event.changedTouches)
	{
		const FirstTouch = event.changedTouches[0];
		mouse.x = (FirstTouch.clientX / window.innerWidth) * 2 - 1;
		mouse.y = -(FirstTouch.clientY / window.innerHeight) * 2 + 1;
	}
}

function onDocumentMouseMove(event) 
{
	mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
	mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;


}

function onDocumentMouseUp( event ) {

	if(intersects.length > 0) 
	{
		const UnitMeta = UNITS_PLACEMENT.filter(U => U.serial === intersects[0].object.name)[0];
		if(UnitMeta.serial)
		{
			store.dispatch("selectUnit", UnitMeta);
			selectUnit(intersects[0].object);
		}
	}
}


function onFloorSelected( event ) {

	// deselect current selected unit
	deselectUnit();

	const { floor } = event.detail;

	let targetFloor = PAVILLION_PARTS[floor];
	let lookAtTarget;
	lookAtTarget = targetFloor.defaultPosition;
	const { x , y, z } = controls?.target;

	

	const tweens = PAVILLION_PARTS[floor].animateToFloor();
	tweens[0].onStart(() => { 
		outlinePass.selectedObjects = PAVILLION_PARTS[floor].units;
	})

	activeTweens = [  ...tweens ];
	
	
	currentFloor = floor;	
	ga('send', {
		hitType: 'pageview',
		page: currentFloor
	  });
}


export function animate() {
	for( const tween of activeTweens ){
		if( tween ) tween.update();
	}
	requestAnimationFrame( animate );
	render();
    controls.enabled = store.getters.InitAnimationDone;
	controls.update();
	if ( stats ) stats.update();
}

function render() {

	//const activeCamera = shouldUseOrtoCamera.orto ? cameraOrto : cameraPersp ;
	//controls.object = activeCamera


	// update rotation of billboard assets
	billboardAssets.forEach(element => element.rotation.setFromRotationMatrix( camera.matrix ));

	const visibleUnits = UNITS_PLACEMENT
		.filter( x => x.floor === currentFloor )
		.map( x => x.sceneObject )
		.filter(obj => !!obj);
	
	raycaster.setFromCamera(mouse, camera);
	intersects = raycaster.intersectObjects(visibleUnits, true);
	
	renderer.render( scene, camera);
	composer.render();
}



function getCenterPoint(mesh : THREE.Mesh, desiredPosition : THREE.Vector3) : THREE.Vector3 {
    const cloned = mesh.clone();
	if(desiredPosition) {
		cloned.position.x = desiredPosition.x;
		cloned.position.y = desiredPosition.y;
		cloned.position.z = desiredPosition.z;
	};
	const geometry = cloned.geometry;
    geometry.computeBoundingBox();
    const center = new THREE.Vector3();
    geometry.boundingBox.getCenter( center );
    cloned.localToWorld( center );
    return center;
}

function addGUI() {

	if(!DEBUG_MODE) return;

	const gui = new GUI()
	// const cameraFolder = gui.addFolder('camera');
	// cameraFolder.add(shouldUseOrtoCamera, "orto");
	// cameraFolder.open();
	const matFolder = gui.addFolder('pavillion mat');
	//matFolder.add(devTransaprentMaterial, "opacity", 0, 1);
	matFolder.open();

	const params = {
		edgeStrength: 7.0,
		edgeGlow: 0.7,
		edgeThickness: 5.0,
		pulsePeriod: 1,
		rotate: false,
		usePatternTexture: false,
		visibleEdgeColor: "#ffab00"
	};

	// Init gui


	gui.add( params, 'edgeStrength', 0.01, 10 ).onChange( function ( value ) {

		outlinePass.edgeStrength = Number( value );

	} );

	gui.add( params, 'edgeGlow', 0.0, 1 ).onChange( function ( value ) {

		outlinePass.edgeGlow = Number( value );

	} );

	gui.add( params, 'edgeThickness', 1, 4 ).onChange( function ( value ) {

		outlinePass.edgeThickness = Number( value );

	} );

	gui.add( params, 'pulsePeriod', 0.0, 5 ).onChange( function ( value ) {

		outlinePass.pulsePeriod = Number( value );

	} );

	function Configuration() {
		this.visibleEdgeColor = '#ffffff';
	}

	const conf = new Configuration();
	gui.addColor( conf, 'visibleEdgeColor' ).onChange( function ( value ) {
		outlinePass.visibleEdgeColor.set( value );
	} );

}

