
//@ts-nocheck

import Vue from 'vue'
import Vuex from 'vuex'

/* services */
import { ReginCloudAPIClient } from "@/services/regincloud";
import { SiemensCloudAPIClient } from "@/services/siemenscloud";
import { WeatherAPIClient } from "@/services/weatherapi";

/* types */

import { Device, Variable } from "@/types/regincloud/Device";
import { WeatherData } from "@/types/weatherapi";

import {PanelData, PAVILLION_FLOORS } from "@/types";
import { INITIAL_FLOOR } from "@/threejs/constants";
import UNITS from "@/threejs/pavillion_units";

Vue.use(Vuex)


import UnitTexts from "@/../public/assets/UnitTexts.json";

export default new Vuex.Store({
    state: 
    {
        floor: INITIAL_FLOOR,
        LoadingDone: false,
        LoadingProgress: 0,
        InitAnimationDone: false,
        OnFloorSelectedCallback: undefined,
        SelectedUnit: { 
            Serial: undefined,
            Preview: "",
            Updater: undefined,
            IsQueringData: false,
            Data: {} 
        },
        UnitDetails: UnitTexts,
        DaysSinceExpoStart: 0,
        SavedEnergy: 0,
        DailyFreshAirDelivered: 0,
        CurrentWeatherData: { location: undefined, current: undefined } as WeatherData,
        RecentlyQueriedUnitData: new Map<Number, Object>(),
        ReginCloudClient: new ReginCloudAPIClient(),
        SiemensCloudClient: new SiemensCloudAPIClient(),
        WeatherClient: new WeatherAPIClient(),
        ShowUserInfo: false
    },

    getters: 
    {
        IsMobile(state): boolean { return window.innerWidth < 600; },
        
        IsUnitSelected: (state): boolean => { return state.SelectedUnit.Serial; },

        ReginCloudClient: (state): ReginCloudAPIClient => { return state.ReginCloudClient; },

        Floor: (state): string => { return state.floor; },
        SelectedUnit: (state): any => { return state.SelectedUnit; },

        SelectedUnitName: (state): string => { return state.UnitDetails[state.SelectedUnit.Serial]?.name; },
        SelectedUnitTitle: (state): string => { return state.UnitDetails[state.SelectedUnit.Serial]?.title; },
        SelectedUnitDescription: (state): string => { return state.UnitDetails[state.SelectedUnit.Serial]?.description; },
        SelectedUnitLocation: (state): string => { return state.UnitDetails[state.SelectedUnit.Serial]?.location; },
        SelectedUnitSpecs: (state): string => { return state.UnitDetails[state.SelectedUnit.Serial]?.specs; },

        LoadingDone: (state): boolean => { return state.LoadingDone },
        LoadingProgress: (state): boolean => { return state.LoadingProgress },
        InitAnimationDone: (state): boolean => { return state.InitAnimationDone },
        

        // savedEnergy: (state): string => { return Number(state.SavedEnergy.toFixed(2)).toLocaleString('en', { minimumFractionDigits: 2 }); }
        savedEnergy: (state): number => { return state.SavedEnergy; },
        savedCo2: (state): number => { return state.SavedEnergy * 0.4; },

        DaysSinceExpoStart: (state): number => { return state.DaysSinceExpoStart; },

        DailyFreshAirDelivered: (state): number => { return state.DailyFreshAirDelivered; },

        DubaiAirQuality(state) : string 
        { 
            const IndexToString = [
                "",
                "Good", "Good", "Good",             // 1-3
                "Moderate", "Moderate", "Moderate", // 4-6
                "Poor", "Poor", "Poor",             // 7-9
                "Very Poor"                         // 10
            ]
            const Current = (state.CurrentWeatherData as WeatherData).current;
            return `${Current ? IndexToString[Current.air_quality['gb-defra-index'] || 4] : 'Moderate'} Outdoor Air Quality`; 
        },

        DubaiHumidity(state) : string 
        { 
            const Current = (state.CurrentWeatherData as WeatherData).current;
            return `${Current ? Current.humidity.toFixed(0) : '25'}% Humidity`; 
        },

        DubaiTemperatur(state) : string 
        {
            const Current = (state.CurrentWeatherData as WeatherData).current;
            return `${Current ? Current.temp_c.toFixed(0) : '30'}°C / ${Current ? Current.temp_f.toFixed(0) : '86'}°F`; 
        },
        DaysSinceExpoStart: (state): number => { return state.DaysSinceExpoStart; },

        ShowUserInfo: (state) => { return state.ShowUserInfo; }
    },

    mutations: 
    {
        /** sets current floor of the pavillion and affects the 3D scene */
        setFloor: function (state, floor: PAVILLION_FLOORS) { 
          state.floor = floor;
          const event = new CustomEvent('floor-selected', { detail: {floor} });
          document.dispatchEvent(event);
        },
        /** sets current floor of the pavillion and affects only the UI part */
        setFloorInUI: function ( state, floor: PAVILLION_FLOORS){
            state.floor = floor;
            if( state.OnFloorSelectedCallback ){ 
                state.OnFloorSelectedCallback( floor );
            }
        },
        setOnFloorSelectedCallback: function( state, fn: Function){
            state.OnFloorSelectedCallback = fn;
        },
        deselectUnit(state) : void 
        {
            clearInterval(state.SelectedUnit.Updater);

            state.SelectedUnit.Serial = undefined;
            state.SelectedUnit.Updater = undefined;
            state.SelectedUnit.IsQueringData = false;
            state.SelectedUnit.Data = {};

            // @hack to communicate with ThreeJS scene and delesect highlighted unit
            document.dispatchEvent(new CustomEvent('panel-closed'));
        },

        loadingDone( state ) : void {
            state.LoadingDone = true;
        },

        loadingProgress( state, progress ) : void { 
            state.LoadingProgress = progress;
        },
        
        initAnimationDone( state ) : void {
           state.InitAnimationDone = true;
        },

        setSelectedUnitSerial(state, Serial: string) : void { state.SelectedUnit.Serial = Serial; },
        setSelectedUnitPreview(state, Preview: string) : void { state.SelectedUnit.Preview = Preview; },
        setSelectedUnitUpdater(state, Updater: number) : void { state.SelectedUnit.Updater = Updater; },
        setSelectedUnitQueryState(state, IsQuering: boolean) : void { state.SelectedUnit.IsQueringData = IsQuering; },
        setSelectedUnitVariables(state, Variables: Variable[]): void { Vue.set(state.SelectedUnit.Data, 'Variables', Variables); },

        setRecentQueriedUnitData(state, { UnitSerial, UnitData }): void { state.RecentlyQueriedUnitData.set(UnitSerial, UnitData); },

        setDailyFreshAirDelivered(state, value): void { state.DailyFreshAirDelivered = value; },
        setSavedEnergy(state, value): void { state.SavedEnergy = value; },
        setDaysSinceExpoStart(state, value): void { state.DaysSinceExpoStart = value; },
    
        setWeatherData(state, value): void { state.CurrentWeatherData = value; },

        openUserInfo(state): void { state.ShowUserInfo = true; },
        closeUserInfo(state): void { state.ShowUserInfo = false; },
    },

    actions: 
    {
        async QueryDubaiWeather({ commit, state}) : Promise<void>
        {
            return state.WeatherClient.ReadWeatherData('Dubai')
                .then((Data) => { commit("setWeatherData", Data); });
        },

        /** Determine the initial energy savings since the beginning of expo 2020 and initialize routine to constantly update the eco panel value. */
        async InitializeEcoPanel({ commit, state }): Promise<void> 
        {
            const SampleSavingkWh = (): number => { return (36.195961820955326 * Math.random()) + 225.98051899104325; };
            const SampleHourCubicMeterAirMoved = (): number => { return (2002.8449517242423 * Math.random()) + 36580.55444423754; };

            // compute initial savings value since expo2020 begin
            const Expo2020Start = new Date(2021, 9, 1);
            const Diff = Date.now() - Expo2020Start.getTime();
            const Hours = Math.floor(Diff) / (1000 * 60 * 60);
            commit("setDaysSinceExpoStart", Math.floor(Hours / 24));

            let AirMovedSince = 0;
            for(let i = 0; i < 24; i++) { AirMovedSince += (SampleHourCubicMeterAirMoved()); }
            commit("setDailyFreshAirDelivered", AirMovedSince);

            let EnergySavedSince = 0;
            for(let i = 0; i < Hours; i++) { EnergySavedSince += (SampleSavingkWh()); }
            commit("setSavedEnergy", EnergySavedSince);

            // update eco panel value every second
            const SampleInterval = 2; // seconds
            const kWhToInterval = (60 /* minutes */ / SampleInterval) * 60;
            setInterval(() => { commit("setSavedEnergy", state.SavedEnergy + (SampleSavingkWh() / kWhToInterval)); }, SampleInterval * 1000);
        },

        /** Query all unit values on application start */
        async QueryDefaultUnitValues({ dispatch }): Promise<void> {
            return Promise.all(UNITS.map(({ serial }) => {  
                if(serial) { dispatch("ReadDeviceData", serial);}
            }))
        },

        /** Update handler to get new data values for currently selected unit */
        async UpdateSelectedUnitData({ commit, dispatch, state }): Promise<void>
        {
            const DeviceSerial = state.SelectedUnit.Serial;
            commit("setSelectedUnitQueryState", true);
            dispatch("ReadDeviceData", DeviceSerial);
        },

        /** Query current device data for given serial number */
        async ReadDeviceData({ commit, state }, DeviceSerial): Promise<void>
        {
            const Unit = UNITS.find(u => u.serial === DeviceSerial);
            if(!Unit) 
            {
                commit("setSelectedUnitVariables", []); 
                return; 
            }

            if(Unit.model_type === "Geniox")
            {
                await state.ReginCloudClient
                    // read devices variables from regincloud
                    .ReadDevice(DeviceSerial)
                    // filter received variables
                    .then(device => 
                    {
                        if(!device)
                        {
                            commit("setSelectedUnitVariables", []);
                        }

                        const VariablesOfInterest = [
                            "AI_IntakeAirTemp", // 1
                            "AI_SupplyAirTemp", // 2
                            "AI_ExtractAirTemp", // 3
                            "AI_ExhaustAirTemp", // 4
                            "AI_EfficiencyTemp",// 5
                            "AI_RoomTemp1", // 6
                            "AI_EAFFlow",
                            "AI_SAFFlow",
                            "SFP",
                        ];
                        
                        device.variables = device.variables
                            .filter(v => VariablesOfInterest.includes(v.name))
                            // only retrieve variables that actuall have a value
                            .filter(v => v.value);

                        // somehow the API returns some varaibles twice. Here we make sure that we show no duplicates
                        let Unique = []
                        for(const Variable of device.variables)
                        {
                            if(Unique.find(v => v.name === Variable.name))
                                continue;

                            Unique.push(Variable);
                        }
                        device.variables = Unique;

                        /** 
                         * Updating device values is a long runing request, in the mean time the user might have changed the current
                         * selected unit. This check prevents the selected unit values from being overriden. 
                         */
                        if(state.SelectedUnit && state.SelectedUnit.Serial === DeviceSerial)
                        {
                            commit("setSelectedUnitVariables", device.variables.filter(v => VariablesOfInterest.includes(v.name)));
                            commit("setSelectedUnitQueryState", false);
                        }

                        // cache this latest queried unit data
                        commit("setRecentQueriedUnitData", { UnitSerial: DeviceSerial, UnitData: device });
                    })
                    .catch(() => {});
            }
            // Sysaqua
            else
            {
                const device = {};
                device.variables = await state.SiemensCloudClient.ReadDevice(DeviceSerial);

                /** 
                 * Updating device values is a long runing request, in the mean time the user might have changed the current
                 * selected unit. This check prevents the selected unit values from being overriden. 
                 */
                 if(state.SelectedUnit && state.SelectedUnit.Serial === DeviceSerial)
                 {
                     commit("setSelectedUnitVariables", device.variables);
                     commit("setSelectedUnitQueryState", false);
                 }

                 // cache this latest queried unit data
                 commit("setRecentQueriedUnitData", { UnitSerial: DeviceSerial, UnitData: device });
            }
        },
        async selectUnit({ commit, dispatch, state }, UnitMetadata): void 
        {
            const Serial = UnitMetadata.serial; 
            const Preview = UnitMetadata.preview;
            const ModelType = UnitMetadata.model_type;

            // deselect current unit, if any
            commit("deselectUnit");
            // set current selected unit serial number
            commit("setSelectedUnitSerial", Serial);
            // set preview image
            commit("setSelectedUnitPreview", Preview);

            // Check, if we recently selected this unit and have the old values
            const LatestDeviceData = state.RecentlyQueriedUnitData.get(Serial);
            if(LatestDeviceData)
            {
                commit("setSelectedUnitVariables", LatestDeviceData.variables);
            }

            // set unit value updater
            commit("setSelectedUnitUpdater", setInterval(async () => { if(!state.SelectedUnit.IsQueringData) { await dispatch("UpdateSelectedUnitData"); } }, 5000));

            // setInterval first calls the 'UpdateSelectedUnitData' after the first timeout expired, we do not want to wait so long
            await dispatch("UpdateSelectedUnitData");
        }
    }
});
