import { render, staticRenderFns } from "./EcoPanel.vue?vue&type=template&id=385d066a&scoped=true&"
import script from "./EcoPanel.vue?vue&type=script&lang=js&"
export * from "./EcoPanel.vue?vue&type=script&lang=js&"
import style0 from "./EcoPanel.vue?vue&type=style&index=0&id=385d066a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "385d066a",
  null
  
)

export default component.exports