<template>
    <div  class="icon" @click="clicked" :style="cssVars">
         <font-awesome-icon  :icon="icon" />
    </div>
</template>

<script>
    
    export default {
        props: [ "callback", "iconType", "fontSize", "dimensions", "withBackground"],
        data () {
            return {   
                icon: this.iconType,
                a: "a"
            }
        },
        methods: {
          clicked: function () {   this.callback() },
        },
        computed: {
            cssVars() {
                return {
                    '--font-size': this.fontSize || "18pt",
                    '--dim': this.dimensions || "24px",
                    '--bg': this.withBackground ? `linear-gradient(0deg, rgba(255, 255, 255, 0.92), rgba(255, 255, 255, 0.92)), #000000` : "none",
                    '--box-shadow': this.withBackground ? `0px 2px 12px rgba(0, 0, 0, 0.2)` : `unset`,
                    '--border': this.withBackground ? `2px solid #4A5D6D` : `unset`
                }
            }
        },
    }
</script>

<style scoped>
    .icon {
        color: black;
        pointer-events: auto;
        font-size: var(--font-size);
        cursor: pointer;
        width: var(--dim);
        height: var(--dim);
        background: var(--bg);
        border: var(--border);
        box-sizing: border-box;
        box-shadow: var(--box-shadow);
        border-radius: 43px;
        font-family: Font Awesome 5 Pro;
        color: #4A5D6D;
        letter-spacing: 0.5px;
    }
</style>