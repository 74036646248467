<template>
<div class="container">
	<div v-for="(image, key) in Images" :key="image.order">
		<img class="frame" :src='image.src' :style='ImageStyle(key + 1)' />
  	</div>
</div>
</template>
<script>

	import TWEEN from '@tweenjs/tween.js';
	

	export default {

		props: [ 'SrcBaseFn', 'FrameCount' ],
		data: function(){
			
			
			return {
				frame: 1,
			}
		},
		mounted(){ 
			function animate () {
        			if (TWEEN.update()) {
          				requestAnimationFrame(animate)
       			}
      		}

      		new TWEEN.Tween({ number: 1 })
        		.to({ number: this.FrameCount }, 3500)
				.onUpdate(({ number: x }) => {  
					const rounded = Math.round( x );
					if( this.frame !== rounded ) this.frame = rounded;
				} )
				.repeat(Infinity)
        		.start();
      		
			animate();
		},
		computed: {
			Images() { 
				return Array(this.FrameCount).fill(0).map(( _, i ) => 
				({ order: i, src: this.SrcBaseFn( i + 1 ) })
			)
		}
		},
		methods : {
			ImageStyle( key ){
				return `
					 display: ${key === this.frame  ? "block" : "none" }
				`;
			}
		}


	};
</script>

<style scoped>
	.container {
		height: 150px;
	}
	.frame {
		transform: translateY(-25px);
	}
</style>