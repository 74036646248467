
//@ts-nocheck

import TWEEN from '@tweenjs/tween.js';
import * as T from "@/types/index";
import UNITS from "./pavillion_units";

const TWEEN_SPEED = 900;

const PAVILLION_PARTS = { 
    [T.PAVILLION_FLOORS.GROUND_FLOOR]: {
        initPosition: { x: 0, y: 10000, z: 0 },
        defaultPosition: { x: 0, y:0, z: 0 },
        path: "assets/pavillion_updated/EXPO_21_v5_edited2.glb",
        model: null,
        units: [],
        animateToFloor: function (  ) : Array<typeof TWEEN | null> {
           
            return makeAnimateToFloor(
                {   
                    firstTarget: "upPosition",
                    secondTarget: "upPosition",
                    thirdTarget: "upPosition",
                    unitsUpBy: 20000,
                }
            )();
        }
    },
    [T.PAVILLION_FLOORS.FIRST_FLOOR]: {
        initPosition: { x: 0, y: 10000, z: 0 },
        defaultPosition: { x: 0, y: 0, z: 0 },
        upPosition: { x: 0, y: 10000, z: 0 },
        path: "assets/pavillion_updated/EXPO_22_v5_edited3.glb",
        model: null,
        units: [],
        animateToFloor: function (  ) : Array<typeof TWEEN | null> {
            
            return makeAnimateToFloor(
                {   
                    firstTarget: "defaultPosition",
                    secondTarget: "upPosition",
                    thirdTarget: "upPosition",
                    unitsUpBy: 20000,
                    middleUnitsUpBy: 0,
                }
            )();
        }
    },
    [T.PAVILLION_FLOORS.SECOND_FLOOR]: {
        initPosition: { x: 0, y: 10000, z: 0 },
        defaultPosition: { x: 0, y: 0, z: 0 },
        upPosition: { x: 0, y: 10000, z: 0 },
        path: "assets/pavillion_updated/EXPO_23_v5_edited2.glb",
        model: null,
        units: [],
        animateToFloor: function (  ) : Array<typeof TWEEN | null> {
            
            return makeAnimateToFloor(
                {   
                    firstTarget: "defaultPosition",
                    secondTarget: "defaultPosition",
                    thirdTarget: "upPosition",
                    unitsUpBy: 0,
                }
            )();
        }
    },
    [T.PAVILLION_FLOORS.WHOLE_PAVILLION_VIEW]: {
        initPosition: { x: 0, y: 10000, z: 0 },
        defaultPosition: { x: 0, y: 0, z: 0 },
        upPosition: { x: 0, y: 10000, z: 0 },
        path: "assets/pavillion_updated/EXPO_24_v5_edited2.glb",
        model: null,
        units: [],
        animateToFloor: function (  ) : Array<typeof TWEEN | null> {
            return makeAnimateToFloor(
                {   
                    firstTarget: "defaultPosition",
                    secondTarget: "defaultPosition",
                    thirdTarget: "defaultPosition",
                    unitsUpBy: 0,
                }
            )();
        }
    },
};

function makeTweenForFloor({ model }, toPosition : { x: number, y: number, z: number }) {
    
    const clonedTargetPos = JSON.parse(JSON.stringify(toPosition));
    return new TWEEN.Tween({ x:  model.position.x, y: model.position.y, z: model.position.z })
        .to(clonedTargetPos, TWEEN_SPEED)
        .easing(TWEEN.Easing.Quartic.InOut)
        .onUpdate(({ x , y, z }) => { 

           
            model.position.x = x;
            model.position.y = y;
            model.position.z = z;
        })
        .start();
}

function makeAnimateToFloor ( { firstTarget, secondTarget, thirdTarget, unitsUpBy = 0, middleUnitsUpBy = null }  ) : Function {
    return function () : Array<TWEEN | null> {
            let firstTween = null, secondTween = null, thirdTween = null;
            
            const first = PAVILLION_PARTS[T.PAVILLION_FLOORS.FIRST_FLOOR];
            const second = PAVILLION_PARTS[T.PAVILLION_FLOORS.SECOND_FLOOR];
            const third = PAVILLION_PARTS[T.PAVILLION_FLOORS.WHOLE_PAVILLION_VIEW];


            firstTween = makeTweenForFloor(first, first[firstTarget], "first");
            secondTween = makeTweenForFloor(second, second[secondTarget]);
            thirdTween = makeTweenForFloor(third, third[thirdTarget]);

            const unitsTweens = UNITS.filter( x => x.floor === T.PAVILLION_FLOORS.SECOND_FLOOR || x.floor === T.PAVILLION_FLOORS.FIRST_FLOOR)
            .map(({ sceneObject, position, floor }) => 
                    { 
                       return new TWEEN.Tween({ y: sceneObject.position.y })
                        .to({ y: position.y + (( Number.isInteger(middleUnitsUpBy) && T.PAVILLION_FLOORS.FIRST_FLOOR === floor) ? middleUnitsUpBy : unitsUpBy) })
                        .easing(TWEEN.Easing.Quartic.InOut)
                        .onUpdate(({ y }) => {  sceneObject.position.y = y;})
                        .start()
                    }
                );

            return [ firstTween, secondTween, thirdTween, ...unitsTweens ];
    }
};



export default PAVILLION_PARTS;