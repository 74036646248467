//@ts-nocheck
import { APIClient } from "@/services";

// types
import { WeatherData } from "@/types/weatherapi";

export class WeatherAPIClient extends APIClient 
{
    constructor()
    {
        super("https://api.weatherapi.com/v1/current.json", "");
    }

    public async ReadWeatherData(Location: string): Promise<WeatherData>
    {
        const Params = new URLSearchParams();
        Params.set('key', '279f66e0d2944cd992e71346211510');
        Params.set('q', Location);
        Params.set('aqi', 'yes');

        return this.GET("", Params);
    }
}