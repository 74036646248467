export interface PanelData
{
    title: string,
    pic: string,
    description: string,
    tableHeaders: Array<string>,
    tableRows: Array<Array<string>>,
}

export enum PAVILLION_FLOORS {
    WHOLE_PAVILLION_VIEW = "TOP",
    SECOND_FLOOR = "2F",
    FIRST_FLOOR = "1F",
    GROUND_FLOOR = "GF",
}


export interface Primitive3DPosition {
    x: number,
    y: number,
    z: number
}


export type LinearCameraAnimation = {
    startPoint: Primitive3DPosition,
    endPoint: Primitive3DPosition,
    currentPoint: Primitive3DPosition,
    approximation: Primitive3DPosition,
}