import { APIClient } from "@/services";

export class SiemensCloudAPIClient extends APIClient 
{
    private Subscription: number[] = [48, 56, 97, 51, 55, 48, 97, 49, 56, 101, 50, 48, 52, 55, 56, 102, 97, 98, 54, 53, 102, 97, 52, 102, 52, 48, 102, 55, 54, 48, 48, 57];
    private Username: number[] = [109, 105, 99, 104, 97, 101, 108, 46, 115, 99, 104, 109, 105, 100, 116, 64, 115, 121, 115, 116, 101, 109, 97, 105, 114, 46, 100, 101];
    private Password: number[] =  [81, 119, 101, 114, 116, 122, 64, 49, 50, 51, 52, 53, 54];

    private IsAuthenticated: boolean = false;

    constructor()
    {
        super("https://api.climatixic.com/");

        this.instance.defaults.headers.common['Ocp-Apim-Subscription-Key'] = String.fromCharCode(...this.Subscription);
    }


    /**
     * the clients tries to retrieve a new access token.
     * @returns True if successfully updated access token.
     */
    public async Authenticate() : Promise<void>
    {
        var params = new URLSearchParams({
            grant_type: "password",
            username: String.fromCharCode(...this.Username),
            password: String.fromCharCode(...this.Password),
            expire_minutes: "1"
        });
        
        return this.SEND("Token/", "POST", 
        {
            data: params,
            headers: 
            { 
                "Content-Type": "application/x-www-form-urlencoded" 
            },
        })
        .then(data => 
        {
            if(!data.access_token) { this.IsAuthenticated = false; }
 
            // update access token
            this.instance.defaults.headers.common['Authorization'] = `Bearer ${data.access_token}`;
            this.IsAuthenticated = true;
        })
        .catch(error => 
        {
            this.IsAuthenticated = false; 
        });
    }

    public async ReadDevice(serial: string) : Promise<any[]>
    {
        await this.Authenticate();


        const VariablesOfInterest = {

            "P719db57d-16c7-461e-bf1b-62df8fe35d6a;1!N3ASTFPG_3I6P-U": 
            {
                type: "Float",
                title: "Current setp. cool",
                unit: "°C"
                // max. 64
            },
            "P719db57d-16c7-461e-bf1b-62df8fe35d6a;1!N3ASTGLIJVSD6AN": 
            {
                type: "Float",
                title: "Outer air temperature",
                unit: "°C"
                // max 85
            },
            "P719db57d-16c7-461e-bf1b-62df8fe35d6a;1!N3ASTG87PLI58T-": 
            {
                type: "Float",
                title: "Capacity",
                unit: "%"
                // max 100
            },
            "P719db57d-16c7-461e-bf1b-62df8fe35d6a;1!N3ASTGLIKSE6D1P": 
            {
                type: "Float",
                title: "Entering water temperature",
                unit: "°C"
                // max 85
            },
            "P719db57d-16c7-461e-bf1b-62df8fe35d6a;1!N3ASTGLIKLE5G1_": 
            {
                type: "Float",
                title: "Leaving water temperature",
                unit: "°C"
                // max 85
            },
            "P719db57d-16c7-461e-bf1b-62df8fe35d6a;1!N3ASTGLIL2V01N9": 
            {
                type: "Boolean",
                title: "Compressor 1 Circuit 1",
                unit: ""
                // max 1
            },
            "P719db57d-16c7-461e-bf1b-62df8fe35d6a;1!N3ASTGLIK_F8MTH": 
            {
                type: "Boolean",
                title: "Compressor 2 Circuit 1",
                unit: ""
                // max 1
            },
            "P719db57d-16c7-461e-bf1b-62df8fe35d6a;1!N3ASTGLIL3X0GT9": 
            {
                type: "Boolean",
                title: "Compressor 1 Circuit 2",
                unit: ""
                // max 1
            },
            "P719db57d-16c7-461e-bf1b-62df8fe35d6a;1!N3ASTGLIL0H6N67": 
            {
                type: "Boolean",
                title: "Compressor 2 Circuit 2",
                unit: ""
                // max 1
            }
        } as Record<string, any>;

        let data: any[] = [];
        for(let page = 0; page < 5; page++)
        {
            const node = `${serial};DPPage${page}`;
            const response = await this.GET(`ViewNodes/${node}/values`)
                .then((response) => 
                { 
                    for(const key of Object.keys(response.values)) 
                    { 
                        const valueDetails = VariablesOfInterest[key];
                        if(valueDetails)
                        {
                            // hack: format values to match regincloud layout, this is required otherwise UI won't work.
                            data.push(
                            { 
                                name: key,
                                title: valueDetails.title,
                                unit: valueDetails.unit,
                                type: valueDetails.type,
                                value: response.values[key] 
                            });
                        } 
                    } 
                });
        }
        
        return data;
    }
}