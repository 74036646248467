










//@ts-nocheck
export default {
    props: ["tabs"],

    data() 
    {
        return {
            ActiveTab: undefined
        }
    },

    mounted()
    {
        // add click event listener to all tabs
        const Tabs = this.$refs.tabs.querySelectorAll(".tab");
        let ActiveTab = undefined;

        for(const Tab of Tabs)
        {
            (Tab as HTMLElement).addEventListener('click', () => { this.OnTabClick(Tab); });

            // get active tab
            const TargetId = (Tab as HTMLElement).getAttribute('target');
            const Target = document.querySelector(`#${TargetId}`);
            if(Target && (Target as HTMLElement).hasAttribute('active')) 
            {
                ActiveTab = Tab;
            }
        }

        // if no active tab specified, select first tab by default 
        if(Tabs.length > 0 && !ActiveTab) { ActiveTab = Tabs[0]; }

        if(ActiveTab) { this.OnTabClick(ActiveTab); }
    },

    methods: 
    {
        OnTabClick(Tab) 
        {
            const TargetId = (Tab as HTMLElement).getAttribute('target');
            if(TargetId != this.ActiveTab)
            {
                for(const tab of this.$refs.tabs.querySelectorAll(".tab")) { (tab as HTMLElement).removeAttribute('active'); }

                if(this.ActiveTab)
                {
                    const OldTargetId = this.ActiveTab;
                    const OldTarget = document.querySelector(`#${OldTargetId}`);
                    OldTarget.removeAttribute('active');
                }

                const Target = document.querySelector(`#${TargetId}`);
                Target.setAttribute('active', '');
                Tab.setAttribute('active', '');

               // remember active tab
               this.ActiveTab = TargetId;
            }
        }
    }
}
