<template>
    <div class="weather-panel">
        <div class="weather-panel-title">Currently in Dubai:</div>
        <div ref="fader" class="weather-panel-entry">
            <div :key="index">
                <div v-if="index == 0" style="width: 100%">
                    <i class="weather-panel-entry-icon fa-light fa-clock" />
                    <span class="weather-panel-entry-label">{{ DubaiTime }}</span>
                </div>
                <div v-else-if="index == 1">
                    <i class="weather-panel-entry-icon fa-light fa-temperature-half" />
                    <span class="weather-panel-entry-label">{{ DubaiTemperatur }}</span>
                </div>
                <div v-else-if="index == 2">
                    <i class="weather-panel-entry-icon fa-light fa-droplet-degree" />
                    <span class="weather-panel-entry-label">{{ DubaiHumidity }}</span>
                </div>
                <div v-else-if="index == 3">
                    <i class="weather-panel-entry-icon fa-light fa-wind" />
                    <span class="weather-panel-entry-label">{{ DubaiAirQuality }}</span>
                </div>
            </div>
        </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    data()
    {
        return {
            index: 0,
            DubaiTime: this.UpdateDubaiTime()
        };
    },

    mounted()
    {
        setInterval(() => { this.UpdateDubaiTime(); }, 1000);

        // Increment index, when fade out animation finished
        this.$refs.fader.addEventListener('animationend', (e) =>
        {
            this.index = ((this.index + 1) % 4);
            // hack to retrigger fade out animation
            this.$refs.fader.classList.remove('weather-panel-entry');
            setTimeout(() => { this.$refs.fader.classList.add('weather-panel-entry'); }, 100);

            this.$forceUpdate();
        });
    },

    computed:
    {
        ...mapGetters([
            "DubaiTemperatur",
            "DubaiHumidity",
            "DubaiAirQuality",
        ])
    },

    methods: 
    {
        UpdateDubaiTime() 
        {
            // Credits: https://www.techrepublic.com/article/convert-the-local-time-to-another-time-zone-with-this-javascript/
            function ComputeLocaltime(offset) 
            {
                const d = new Date();
                const utc = d.getTime() + (d.getTimezoneOffset() * 60000);
                const nd = new Date(utc + (3600000*offset));
                return nd.toTimeString().split(":").splice(0, 2).join(":");
            }

            this.DubaiTime = ComputeLocaltime('+4');
        },
    }
}
</script>

<style scoped>
.weather-panel {

    height: 30px;
    padding: 4px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: space-between;

    font-size: 0.7rem;
    font-family: 'Inter';
    font-style: normal;
    line-height: 120%;

    background: #F6F7F8;
}

.weather-panel-title {

    font-weight: bold;
}

@keyframes fadeOut
{
    0%   { opacity: 1; }
    90%  { opacity: 1; }
    100% { opacity: 0; }
}
@-o-keyframes fadeOut
{
    0%   { opacity: 1; }
    90%  { opacity: 1; }
    100% { opacity: 0; }
}
@-moz-keyframes fadeOut
{
    0%   { opacity: 1; }
    90%  { opacity: 1; }
    100% { opacity: 0; }
}
@-webkit-keyframes fadeOut
{
    0%   { opacity: 1; }
    90%  { opacity: 1; }
    100% { opacity: 0; }
}

.weather-panel-entry {
    --fade-duration: 4s;

    -webkit-animation: fadeOut var(--fade-duration) forwards;
    -moz-animation: fadeOut var(--fade-duration) forwards;
    -o-animation: fadeOut var(--fade-duration) forwards;
    animation: fadeOut var(--fade-duration) forwards;
}

.weather-panel-entry-icon {
    width: 16px;
}

.weather-panel-entry-label {
}
</style>