<template>
    <modal name="tutorial"
         :adaptive="true"
         :width="width"
         :height="height"
         :maxWidth="width"
         :maxHeight="height"
         >     
       <div class="content" :style="cssVars">
            <Icon class="i" :fontSize="'14pt'" :iconType="'times'" :dimensions="'26px'" :withBackground="false" :callback="hide"/>
            <img src="assets/tutorial.png"> 
            <button class="bttn" @click="hide">OK</button>
       </div>
    </modal>
</template>

<script>

import { mobileCheck } from "@/utils";

import Icon from "@/components/Icon.vue";

export default {
    name: 'MyComponent',
    components: { Icon },
    data() 
    {
        return {
            IsMobile: false,
            width: 840,
            height: 480,
        }
    },
    methods : { 
        hide : function () { this.$modal.hide('tutorial') }
    },

     mounted()
    {
        this.IsMobile = mobileCheck();
        this.width = this.IsMobile ? 510 : 840;
        this.height = this.IsMobile ? 280 : 480;
    },
    computed: {
            cssVars() {

                return {
                    '--img-height': mobileCheck() ? "196px" : "362px",
                }
            }
        },
    
}
</script>

<style scoped>
    .content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        align-items: center;
    }
    .i {
        align-self: flex-end;
        margin: 10px 8px;
    }
    .bttn {
        width: 80px;
        margin-bottom: 30px;;
    }

    img { 
            height: var(--img-height);
    }
</style>