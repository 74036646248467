<template>
    <div class="vld-parent">
        <VueLoading 
        :active="isLoading" 
        is-full-page background-color="transparent"
        opacity=1 
        lock-scroll 
        blur="2px" 
        loader="dots"/>
        <div class="overlay" :style="cssVars">
            <LinearLoadingIndicator 
            text="Loading fresh air for the Swedish Pavillion ..."
            :loading="loadingValue" />
        </div>
    </div>
</template>

<script>
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import LinearLoadingIndicator from "@/components/LinearLoadingIndicator.vue"

export default {
    props: ["isLoading", "loadingValue"],
    components: {
        VueLoading,
        LinearLoadingIndicator
    },
    computed: {
        cssVars() {
            return {
                '--opacity': this.isLoading ? 1 : 0,
            }
        }
    },
}
</script>


<style scoped>
.vld-parent {
    z-index: 1000; 
}


.overlay {
    width: 100vw;
    height: 100vh;
    opacity: var(--opacity);
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    background: #FFFFFF;
}

.vld-parent > div:first-child { 
    margin-top: 99px;
}
</style>



<style>
/* Hide the default loader animation   */
.vld-icon {
    opacity: 0;
}
</style>