//@ts-nocheck
import { APIClient } from "@/services";

// types
import { Device, Variable } from "@/types/regincloud/Device";

export class ReginCloudAPIClient extends APIClient 
{
    private Account: string = "Dubai";
    private Username: string = "mrstudios";
    private Password: string = "mrstudios";

    private IsAuthenticated: boolean = false;

    constructor()
    {
        super("https://cloudigoapi.regincontrols.com/");

        // add an interceptor to handle bad requests
        this.instance.interceptors.response.use(undefined, (request: any) => { return this.OnResponseError(request); });
    }

    private async OnResponseError(request: any): Promise<void>
    {
        if(request)
        {
            const originalRequest = request.config;

            // only retry this once
            if(!originalRequest.__retry)
            {
                /** This will guard us from creating a infinity loop as we mark the original response with '__retry = true'. */
                originalRequest.__retry = true;

                // Check, if we need to authenticate
                if(request.response.status == 401 /* unauthorized */ || request.response.status == 403 /* Forbidden */ || request.response.status == 429 /* Too many requests */)
                {
                    await this.Authenticate()
                        // when authentication was successful, we need to update the original requests access token
                        .then(success => 
                        {
                            originalRequest.headers = Object.assign(originalRequest.headers, this.instance.defaults.headers.common);
                        });
                }

                // try again
                return await this.instance.request(originalRequest);
            }
        }

        // return the request for further processing...
        return Promise.reject(request);
    }

    /**
     * the clients tries to retrieve a new access token.
     * @returns True if successfully updated access token.
     */
    public async Authenticate() : Promise<void>
    {
        return this.POST("/login", { 
            account: this.Account,
            username: this.Username,
            password: this.Password
        })
        .then(data => 
        {
            if(!data.authToken) { this.IsAuthenticated = false; }

            // update access token
            this.instance.defaults.headers.common['Authorization'] = `Bearer ${data.authToken}`;
            this.IsAuthenticated = true;
        })
        .catch(error => 
        { 
            this.IsAuthenticated = false; 
        });
    }

    public async ReadDevice(serial: string): Promise<Device>
    {
        return this.Authenticate()
            .then(() => 
            {
                return this.POST("graphql", `{ device(serial: \"${serial}\") { name description variables { name unit value type title description } } }`, { 'Cache-Control': 'no-cache' }).then(response => response.data.device);
            });
    }
}