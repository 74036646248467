<template>
    <div class="progressbar-container"> 
        <progress class="pure-material-progress-linear" id="loading" :value="loading" max="100"/>
        <label for="loading" class="progressbar-text">{{text}}</label>
    </div>
    
</template>

<script>
export default {
    props: ["text", "text-size", "loading"]
}
</script>

<style scoped>

.progressbar-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.progressbar-text {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 1.0rem;
    line-height: 120%;
    /* or 24px */

    text-align: center;
    font-feature-settings: 'pnum' on, 'lnum' on;

    /* Systemair/UI/Text */
    color: #002543;

    margin: 8px;
}

.pure-material-progress-linear {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    height: 0.25em;
    color: #037BC7;
    background-color: #037BC70f;
    font-size: 16px;
}

.pure-material-progress-linear::-webkit-progress-bar {
    background-color: transparent;
}

/* Determinate */
.pure-material-progress-linear::-webkit-progress-value {
    background-color: currentColor;
    transition: all 0.2s;
}

.pure-material-progress-linear::-moz-progress-bar {
    background-color: currentColor;
    transition: all 0.2s;
}

.pure-material-progress-linear::-ms-fill {
    border: none;
    background-color: currentColor;
    transition: all 0.2s;
}

/* Indeterminate */
.pure-material-progress-linear:indeterminate {
    background-size: 200% 100%;
    background-image: linear-gradient(to right, transparent 50%, currentColor 50%, currentColor 60%, transparent 60%, transparent 71.5%, currentColor 71.5%, currentColor 84%, transparent 84%);
    animation: pure-material-progress-linear 2s infinite linear;
}

.pure-material-progress-linear:indeterminate::-moz-progress-bar {
    background-color: transparent;
}

.pure-material-progress-linear:indeterminate::-ms-fill {
    animation-name: none;
}

@keyframes pure-material-progress-linear {
    0% {
        background-size: 200% 100%;
        background-position: left -31.25% top 0%;
    }
    50% {
        background-size: 800% 100%;
        background-position: left -49% top 0%;
    }
    100% {
        background-size: 400% 100%;
        background-position: left -102% top 0%;
    }
}

</style>