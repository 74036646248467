import { PAVILLION_FLOORS } from "@/types/index";

const UNITS = [
    {
        "model": "Geniox_Big-Expo_0",
        "model_type": "Geniox",
        "preview": "GenioxGo",
        "serial": '011911220141',
        "floor": PAVILLION_FLOORS.SECOND_FLOOR,
        "sceneObject": null,
        "position": {
            "x": -36.85418701171875,
            "y": 592.10498046875,
            "z": 440.8368225097656
        },
        "rotation": {
            "x": 0,
            "y": 0,
            "z": 0
        }
    },
    {
        "model": "Geniox_Big-Expo_1",
        "model_type": "Geniox",
        "preview": "GenioxGo",
        "serial": '011911220122',
        "floor": PAVILLION_FLOORS.GROUND_FLOOR,
        "sceneObject": null,
        "position": {
            "x": -329.8326721191406,
            "y": 123.57705688476562,
            "z": -447.0395812988281
        },
        "rotation": {
            "x": 0,
            "y": -(Math.PI / 2),
            "z": 0
        }
    },
    {
        "model": "Geniox_Small-Expo_2",
        "model_type": "Geniox",
        "preview": "GenioxSmall",
        "serial": '011904231138',
        "floor": PAVILLION_FLOORS.FIRST_FLOOR,
        "sceneObject": null,
        "position": {
            "x": -80.4464111328125,
            "y": 397.1358642578125,
            "z": -876.100830078125
        },
        "rotation": {
            "x": 0,
            "y": Math.PI ,
            "z": 0
        }
    },
    {
        "model": "Geniox_Big-Expo_4",
        "model_type": "Geniox",
        "preview": "GenioxGo",
        "serial": '011904231128',
        "floor": PAVILLION_FLOORS.SECOND_FLOOR,
        "sceneObject": null,
        "position": {
            "x": 386.7419128417969,
            "y": 538.2841796875,
            "z": 1061.090087890625
        },
        "rotation": {
            "x": 0,
            "y": Math.PI / 2,
            "z": 0
        }
    },
    {
        "model": "Geniox_Big-Expo_5",
        "model_type": "Geniox",
        "preview": "GenioxGo",
        "serial": '011908124872',
        "floor": PAVILLION_FLOORS.SECOND_FLOOR,
        "sceneObject": null,
        "position": {
            "x": -280.5379333496094,
            "y": 538.2841796875,
            "z": 808.8290405273438
        },
        "rotation": {
            "x": 0,
            "y": Math.PI ,
            "z": 0
        }
    },
    {
        "model": "Geniox_Big-Expo_6",
        "model_type": "Geniox",
        "preview": "GenioxGo",
        "serial": '011904231236',
        "floor": PAVILLION_FLOORS.SECOND_FLOOR,
        "sceneObject": null,
        "position": {
            "x": 310.67120361328125,
            "y": 605.1669921875,
            "z": 666.3806762695312
        },
        "rotation": {
            "x": 0,
            "y": -(Math.PI / 2),
            "z": 0
        }
    },
    {
        "model": "Geniox_Big-Expo_7",
        "model_type": "Geniox",
        "preview": "GenioxGo",
        "serial": '011911220139',
        "floor": PAVILLION_FLOORS.SECOND_FLOOR,
        "sceneObject": null,
        "position": {
            "x": 316.6568298339844,
            "y": 583.98974609375,
            "z": 17.875244140625
        },
        "rotation": {
            "x": 0,
            "y": (Math.PI / 2),
            "z": 0
        }
    },
    {
        "model": "Geniox_Big-Expo_8",
        "model_type": "Geniox",
        "preview": "GenioxGo",
        "serial": '011911220137',
        "floor": PAVILLION_FLOORS.SECOND_FLOOR,
        "sceneObject": null,
        "position": {
            "x": 315.3524169921875,
            "y": 583.98974609375,
            "z": -304.58306884765625
        },
        "rotation": {
            "x": 0,
            "y": -(Math.PI / 2),
            "z": 0
        }
    },
    {
        "model": "Geniox_Big-Expo_9",
        "model_type": "Geniox",
        "preview": "GenioxGo",
        "serial": '011911220143',
        "floor": PAVILLION_FLOORS.SECOND_FLOOR,
        "sceneObject": null,
        "position": {
            "x": -331.6009521484375,
            "y": 583.98974609375,
            "z": -109.3593521118164
        },
        "rotation": {
            "x": 0,
            "y": (Math.PI / 2),
            "z": 0
        }
    },
    {
        "model": "Geniox_Big-Expo_10",
        "model_type": "Geniox",
        "preview": "GenioxGo",
        "serial": '011911220120',
        "floor": PAVILLION_FLOORS.GROUND_FLOOR,
        "sceneObject": null,
        "position": {
            "x": -337.9889221191406,
            "y": 123.57705688476562,
            "z": -330.5008544921875
        },
        "rotation": {
            "x": 0,
            "y": Math.PI / 2,
            "z": 0
        }
    },

    
    {
        "model": "Sysaqua-Expo_3",
        "model_type": "Sysaqua",
        "preview": "Sysaqua",
        "serial": "V719db57d-16c7-461e-bf1b-62df8fe35d6a",
        "floor": PAVILLION_FLOORS.SECOND_FLOOR,
        "sceneObject": null,
        "position": {
            "x": -436.52899169921875,
            "y": 533.68408203125,
            "z": 1052.9471435546875
        },
        "rotation": {
            "x": 0,
            "y": 0,
            "z": 0
        }
    }
]


export default UNITS;