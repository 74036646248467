
//@ts-nocheck


import { mobileCheck } from '../../utils';
import * as T from "@/types/index";

// proxy 
export const PROXY_WIDTH = 1500;
export const PROXY_HEIGHT = 400;
export const PROXY_DEPTH = 1500;

// camera settings

export const CAMERA_NEAR = 10;
export const CAMERA_FAR = 10000;
export const CAMERA_INIT_POS = { x: 3745.0958061696174, y: 2965.5839509809175, z: 3495.1933755223326 };


export const START_CAMERA_INIT_POS = {
    "x": 2022.746371092927,
    "y": 1199.6344442305567,
    "z": -1086.1129230873323
};

export const START_CAMERA_INIT_ROT = {
    "x": -2.306570317809335,
    "y": 0.8960365481102579,
    "z": 2.4299018272360478,
    "order": "XYZ"
};




// orbit controls

// export const DAMPING_FACTOR : number = mobileCheck() ? 0.3 : 0.3; 
export const DAMPING_FACTOR : number = 0.15; 

export const MAX_POLAR_ANGLE : number = .8 * (Math.PI / 2);

export const ROTATE_SPEED = 0.5;
export const ZOOM_SPEED = 0.5;

function boundingBoxDistance( WIDTH: number, HEIGHT: number, DEPTH: number) : number  {
    return Math.sqrt(Math.pow(HEIGHT, 2) + Math.pow(Math.sqrt(Math.pow(DEPTH, 2) + Math.pow(WIDTH, 2)) /2, 2));
}

/**
 * real model size: 
 *  x: 140.1.584 cm
 *  y: 2666.358 cm
 *  z: 909.614 cm
 */

const BOUNDING_WIDTH = PROXY_WIDTH;
const BOUNDING_DEPTH = PROXY_DEPTH;
const BOUNDING_HEIGHT = PROXY_HEIGHT * 3 + 10;

const SCENE_MARGIN = 1250;

export const MIN_DISTANCE : number =  boundingBoxDistance(BOUNDING_WIDTH, BOUNDING_HEIGHT, BOUNDING_DEPTH);
export const MAX_DISTANCE : number =  boundingBoxDistance(BOUNDING_WIDTH + SCENE_MARGIN, BOUNDING_HEIGHT + SCENE_MARGIN, BOUNDING_DEPTH + SCENE_MARGIN);


// floor interaction 
export const INITIAL_FLOOR = T.PAVILLION_FLOORS.SECOND_FLOOR;

export const UNITS_SCALE = 30;

// outline pass

export const PULSE_PERIOD = 2.4;
export const EDGE_GLOW = .3;
export const EDGE_THICKNESS = .5;
export const VISIBLE_EDGE_COLOR = '#0088ff';

export const EDGE_STRENGTH_SELECTED = 3;
export const PULSE_PERIOD_SELECTED = 0;
export const EDGE_GLOW_SELECTED = 2;
export const EDGE_THICKNESS_SELECTED = 3;
export const VISIBLE_EDGE_COLOR_SELECTED = '#00beff';
