<template>
    <div id="main" :style="cssVars">
        <div id="ui" :click="deselectUnit" >
            <button class="mr-logo" onclick="window.open('http://mrstudios.eu/','_blank')">
                <img src="assets/logo.svg"/>
            </button>
            <div :style="StyleLogoContainer" class="logos-container">
                <img class="sa-logo" src="assets/systemair-Logo.svg" />
                <img class="expo-logo" v-if="!IsUnitSelected || !IsMobile" src="assets/logo_expo.svg" />
            </div>
            <Loading :isLoading="!LoadingDone" :loadingValue="LoadingProgress" />
            <EcoPanel />
            <WeatherPanel />
            <FloorSelect />
            <Modal />
            <DetailsPanel v-if="IsUnitSelected" :SelectedUnit="SelectedUnit" :onPanelClose="deselectUnit" />
            <UserInfo />
        </div>
    </div>
</template>

<script>
import InitializeScene from "@/threejs";

import { mapGetters, mapMutations, mapActions } from "vuex";

import Vue from "vue";
import VModal from "vue-js-modal";
Vue.use(VModal);

/** components */
import DetailsPanel from "@/components/DetailsPanel.vue";
import Modal from "@/components/Modal.vue";
import Icon from "@/components/Icon.vue";
import Loading from "@/components/Loading.vue";
import EcoPanel from "@/components/EcoPanel.vue";
import FloorSelect from "@/components/FloorSelect.vue";
import WeatherPanel from "@/components/WeatherPanel.vue";
import UserInfo from "@/components/UserInfo.vue";
import {} from "@fortawesome/fontawesome-pro/js/all";

/**types */
import { PanelData,  PAVILLION_FLOORS } from "@/types";

/** font-awesome stuff */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from '@fortawesome/free-solid-svg-icons';

library.add(fas);

Vue.component("font-awesome-icon", FontAwesomeIcon);


Vue.config.productionTip = false;

export default Vue.extend({
    name: "Main",
    data() {
        return {
            bttnTitle: "Show Details Panel",
            floorSlider: {
                values: [ 
                    PAVILLION_FLOORS.GROUND_FLOOR,
                    PAVILLION_FLOORS.FIRST_FLOOR, 
                    PAVILLION_FLOORS.SECOND_FLOOR,
                    PAVILLION_FLOORS.WHOLE_PAVILLION_VIEW  
                ],
                initvalue: this.$store.state.floor,
                current: this.$store.state.floor
            },
        };
    },
    components: {
        DetailsPanel,
        FontAwesomeIcon,
        Icon,
        Modal,
        Loading,
        EcoPanel,
        FloorSelect,
        WeatherPanel,
        UserInfo
    },

    computed: 
    {
        ...mapGetters([
            "Floor",
            "SelectedUnit",
            "IsUnitSelected",
            "LoadingDone"
        ]),

        cssVars() {
            return {
                "--pointer-events":
                this.$store.state.SelectedUnit.Serial ? "none" : "auto",
            };
        },
        StyleLogoContainer(){
            const IsMobile = window.innerWidth < 600;
            return  `
                width: ${IsMobile ? '100%' : 'unset'};
            `;
        },
        LoadingProgress(){
            return this.$store.state.LoadingProgress
        },

        IsMobile() { return window.innerWidth < 600 }
    },
    methods: 
    {
        ...mapMutations([
            "setFloor",
            "deselectUnit"
        ]),

        ...mapActions([
            "InitializeEcoPanel",
            "QueryDefaultUnitValues",
            "QueryDubaiWeather"
        ]),

        onShowModal: function () 
        {
            this.$modal.show("tutorial");
        },
    },

    async mounted() 
    {
        // Create and initialize ThreeJS scene.
        try { InitializeScene(); } catch {}     

        await this.InitializeEcoPanel();
        await this.QueryDefaultUnitValues();
        await this.QueryDubaiWeather();

        document.addEventListener('floor-selected', this.deselectUnit);
    },
});
</script>

<style>
#main {
    background: #4a5d6d;
}

#main > canvas {
    position: fixed;
    display: block;

    /** Stretch canvas across the the entire page. */
    margin: 0;
    padding: 0;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}

#ui {
    position: absolute;
    top: 120px;
    /** Make Sure UI is always in front of the ThreeJS scene */
    z-index: 99;
    pointer-events: none;
}

.mr-logo { 
    position: fixed;
    bottom: 16px;
    left: 16px;
    width: 150px;
    background: transparent;
    border: none;
    pointer-events: auto;
    transition: transform 0.5s;
}

.mr-logo:hover { 
    position: fixed;
    bottom: 16px;
    left: 16px;
    transform: scale(1.1, 1.1);
}

.logos-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 16px;
    z-index: 9999;
}

.sa-logo { height: 32px;  margin-left: 16.5px; }
.expo-logo { height: 56px; margin: 0 16px ; }

.components {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100vw;
    pointer-events: none;
}

.tbd {
    display: flex;
    flex-direction: row;
    width: 30vw;
    justify-content: space-between;
    position: fixed;
    bottom: 30px;
    right: 30px;
}

.question-mark {
    position: fixed;
    top: 20px;
    right: 20px;
    opacity: 0.5;
}

.question-mark:hover {
    opacity: 0.8;
}

.question-mark:active {
    opacity: 1.0;
}
</style>
