<template>
    <div class="details-panel">
        <!-- panel header -->
        <div class="details-panel-header">
            <div class ="details-panel-title-container">
                <div class ="details-panel-location">
                    <!-- <i class="fa-thin fa-location-dot"></i> -->
                    {{ SelectedUnitLocation }}
                </div>
                <div class ="details-panel-title">{{ SelectedUnitName }}</div>
                <div class ="details-panel-subtitle">{{ SelectedUnitTitle }}</div>
            </div>
            <div class="details-panel-close" @click="onPanelClose">
                <i class="fa-light fa-xmark"></i>
            </div>
        </div>

        <!-- rotating unit avatar -->
        <ImageLoop :SrcBaseFn="ImageLoopBaseSrcFn" :FrameCount="30" />

        <!-- unit description -->
        <p class="details-panel-description">{{ SelectedUnitDescription }}</p>


        <Tabs :tabs="tabs" style="flew-grow: 1;">
        </Tabs>
            <TabItem id="realtime" active style="overflow-y: auto;">
                <div class="details-panel-realtime-title-container">
                    <div class="details-panel-realtime-subtitle"><i class="fa-thin fa-cloud-word" style="font-size: 12px; margin-right: 2px;" />Showing realtime cloud data from the unit</div>
                </div>

                <!-- real-time unit state variables -->
                <div v-if="Unit.Data.Variables">
                    <div v-if="Disconnected" class="details-panel-realtime-disconnected">
                        <i class="fa-solid fa-cloud-slash fa-3x" />
                        <div>Sensor connection interrupted.</div>
                    </div>
                    <div v-else>
                        <table class="details-panel-realtime-variables">
                            <tr v-for="(Variable, index) in Unit.Data.Variables" :key="index">
                                <td class="details-panel-realtime-variables-name">{{ PrettyName(Variable) }}</td>
                                <td class="details-panel-realtime-variables-value">{{ PrettyValue(Variable) }}</td>
                                <td class="details-panel-realtime-variables-unit">{{ Variable.unit || FallbackUnit(Variable) }}</td>
                                <td class="details-panel-realtime-variables-indicator">
                                    <div class="details-panel-realtime-variables-indicator-bar" :temperature="IsTemperatureValue(Variable)"><span :style="Capacity(Variable)"></span></div>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div v-else style="padding-top: 50px; justify-content: center; align-items: center;">
                    <LinearLoadingIndicator text="Reading device data ..." />
                </div>
            </TabItem>
            <TabItem id="specs" style="overflow-y: auto;">
                <div class="details-panel-realtime-title-container">
                    <div class="details-panel-realtime-subtitle" style="opacity: 0;">placeholder</div>
                </div>
                <table class="details-panel-realtime-variables">
                    <tr style="line-height: 24px;" v-for="(Spec, index) in SelectedUnitSpecs" :key="index">
                        <td class="details-panel-realtime-variables-name">{{ Spec.title }}</td>
                        <td class="details-panel-realtime-variables-value">{{ Spec.value }}</td>
                        <td class="details-panel-realtime-variables-unit">{{ Spec.unit }}</td>
                        <!-- <td class="details-panel-realtime-variables-indicator"></td> -->
                    </tr>
                </table>
            </TabItem>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

/* components */
import Icon from "@/components/Icon.vue";
import LinearLoadingIndicator from "@/components/LinearLoadingIndicator.vue";
import ImageLoop from "@/components/ImageLoop.vue";
import Tabs from "@/components/Tabs.vue";
import TabItem from "@/components/TabItem.vue";

export default {
    props: 
    {
        "SelectedUnit" : Object,
        "onPanelClose": Function
    },
    components: 
    {
        Icon,
        LinearLoadingIndicator,
        ImageLoop,
        Tabs, TabItem
    },

    data() 
    {
        return {
            name: "panelie panel",
            Unit: this.SelectedUnit,
            IsMobile: false,
            tabs: [
                { 
                    name: 'Realtime Performance', 
                    target: 'realtime' 
                }, 
                { 
                    name: 'Tech Specs', 
                    target: 'specs' 
                }
            ]
        }
    },

    mounted() 
    {
        const IsMobile = window.innerWidth < 600;
        if(IsMobile)
        {
            const EcoPanel = document.querySelector(".details-panel");
            EcoPanel.style.setProperty("width", "calc(100vw - 32px)");
            EcoPanel.style.setProperty("top", "0");
            EcoPanel.style.setProperty("left", "0");
            EcoPanel.style.setProperty("right", "0");
            EcoPanel.style.setProperty("bottom", "0");
            EcoPanel.style.setProperty("padding", "64px 16px");

            const ClosePanel = document.querySelector(".details-panel-close");   
            ClosePanel.style.setProperty("transform", "translateX(7px) translateY(-55px)");
           
            //const DetailsPanelDescription = document.querySelector(".details-panel-description");
            //DetailsPanelDescription.style.setProperty("padding-top", "unset");
            //DetailsPanelDescription.style.setProperty("height", "20px");
            //DetailsPanelDescription.style.setProperty("margin", "34px 0px 7px");
        };

    },

    computed:
    {
        ...mapGetters([
            "SelectedUnitName",
            "SelectedUnitTitle",
            "SelectedUnitDescription",
            "SelectedUnitLocation",
            "SelectedUnitSpecs"
        ]),

        Disconnected() { return !this.SelectedUnit.Data.Variables.length; },

        ImageLoopBaseSrcFn() {
           const base = { 
                "GenioxGo" : "GenioxGo_04_30rotate.35",
                "GenioxSmall" : "Geniox_Small_03_30frames.36",
                "Sysaqua": "Sysaqua_Rotation_v4_30frames.37"
                }[ this.SelectedUnit.Preview ] || "" ;
            return (( i ) => `assets/image_loops/${base}.${ i }.png`)
        }

    },
    methods: 
    {
        PrettyValue: function(DeviceVariable)
        {
            switch(DeviceVariable.type)
            {
                case "Boolean": { return DeviceVariable.value ? 'ON' : 'OFF'; }
                //case "Int": { return DeviceVariable.value; }
                case "Float": { return parseFloat(DeviceVariable.value).toFixed(0);  }
                //case "String": { return DeviceVariable.value; }
                //case "Date": { return DeviceVariable.value; }
                //case "Time": { return DeviceVariable.value; }

                default: { return DeviceVariable.value; }
            }
        },

        Capacity: function(DeviceVariable)
        {
            const VariableUnits = {
         
                "AI_IntakeAirTemp":  50, // 1
                "AI_SupplyAirTemp":  50, // 2
                "AI_ExtractAirTemp":  50, // 3
                "AI_ExhaustAirTemp":  50, // 4
                "AI_EfficiencyTemp":  50,// 5
                "AI_RoomTemp1":  50, // 6
                "AI_EAFFlow":  8000, // 7
                "AI_SAFFlow":  8000,// 8
                "SFP":  4, // 20,
                
                // sysaqua varaibles
                "P719db57d-16c7-461e-bf1b-62df8fe35d6a;1!N3ASTFPG_3I6P-U": 64,
                "P719db57d-16c7-461e-bf1b-62df8fe35d6a;1!N3ASTGLIJVSD6AN": 85,
                "P719db57d-16c7-461e-bf1b-62df8fe35d6a;1!N3ASTG87PLI58T-": 100,
                "P719db57d-16c7-461e-bf1b-62df8fe35d6a;1!N3ASTGLIKSE6D1P": 85,
                "P719db57d-16c7-461e-bf1b-62df8fe35d6a;1!N3ASTGLIKLE5G1_": 85,
                "P719db57d-16c7-461e-bf1b-62df8fe35d6a;1!N3ASTGLIL2V01N9": 1,
                "P719db57d-16c7-461e-bf1b-62df8fe35d6a;1!N3ASTGLIK_F8MTH": 1,
                "P719db57d-16c7-461e-bf1b-62df8fe35d6a;1!N3ASTGLIL3X0GT9": 1,
                "P719db57d-16c7-461e-bf1b-62df8fe35d6a;1!N3ASTGLIL0H6N67": 1
            };

            const MaxCapacity = VariableUnits[DeviceVariable.name] || 100;

            return `width: ${Math.max(0, 1 - Math.min(1, DeviceVariable.value/MaxCapacity)) * 100}%`;
        },

        IsTemperatureValue: function(DeviceVariable)
        {
            const VariableUnits = {
                "AI_IntakeAirTemp":  true, // 1
                "AI_SupplyAirTemp":  true, // 2
                "AI_ExtractAirTemp":  true, // 3
                "AI_ExhaustAirTemp":  true, // 4
                "AI_EfficiencyTemp":  true,// 5
                "AI_RoomTemp1":  true, // 6
                "AI_EAFFlow":  false, // 7
                "AI_SAFFlow": false,// 8
                "SFP":  false, // 20
            };

            return VariableUnits[DeviceVariable.name] || DeviceVariable.unit === '°C';
        },

        FallbackUnit: function(DeviceVariable)
        {
            const VariableUnits = {
                "AI_IntakeAirTemp":  "°C", // 1
                "AI_SupplyAirTemp":  "°C", // 2
                "AI_ExtractAirTemp":  "°C", // 3
                "AI_ExhaustAirTemp":  "°C", // 4
                "AI_EfficiencyTemp":  "°C",// 5
                "AI_RoomTemp1":  "°C", // 6
                "AI_EAFFlow":  "m³/h", // 7
                "AI_SAFFlow":  "m³/h",// 8
                "SFP":  "kW/(m³/s)", // 20
            };

            return VariableUnits[DeviceVariable.name] || DeviceVariable.unit;
        },

        PrettyName: function(DeviceVariable)
        {
            const VariableUnits = {
                "AI_IntakeAirTemp":  "Intake air temperature", // 1
                "AI_SupplyAirTemp":  "Supply air temperature", // 2
                "AI_ExtractAirTemp":  "Extract air temperature", // 3
                "AI_ExhaustAirTemp":  "Exhaust air temperature", // 4
                "AI_EfficiencyTemp":  "Efficiency air temperature",// 5
                "AI_RoomTemp1":  "Room air temperature", // 6
                "AI_EAFFlow":  "Air volume exhaust air fan", // 7
                "AI_SAFFlow":  "Air volume supply air fan",// 8
                "SFP":  "Specific fan power", // 20
            };

            return VariableUnits[DeviceVariable.name] || DeviceVariable.title;
        }
    }
}
</script>

<style>
.details-panel {

    position: fixed;

    width: 400px;
    right: 16px;
    top: 16px;
    bottom: 16px;
    
    padding: 16px 20px;

    display: flex;
    flex-flow: column;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: stretch;
    justify-content:flex-start;
    align-items: stretch;

    /* Systemair/UI/B&W/000 */
    background: #FFFFFF;
    /* Shadow/Medium/Panel */
    box-shadow: 0px 16px 64px rgba(0, 37, 67, 0.2);

    font-family: 'Inter';
    font-style: normal;
    font-weight: normal;
    font-size: 0.9rem;
    line-height: 120%;

    pointer-events: auto;
    z-index: 99;
}

.details-panel-header {
    margin: 0;
    padding: 8px;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between; 
    font-size: 1.4rem;
}

.details-panel-close {
    width: 32px;
    height: 32px;
    font-style: normal;
    font-weight: normal;
    line-height: 32px;
    pointer-events: auto;
    cursor: pointer;
    text-align: end;
    transform: translateX(16px) translateY(-16px);
}

.details-panel-title-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.details-panel-location {

    margin-bottom: 2px;
    /* font-family: DaxlinePro; */
    font-style: normal;
    font-weight: normal;
    font-size: 0.6rem;
    line-height: 115%;
    /* or 41px */

    font-feature-settings: 'pnum' on, 'lnum' on;

    color: #007FA5;
}

.details-panel-title {
    /* font-family: DaxlinePro; */
    font-style: normal;
    font-weight: bold;
    font-size: 1.4rem;
    line-height: 115%;
    /* or 41px */

    font-feature-settings: 'pnum' on, 'lnum' on;
}

.details-panel-subtitle {
    /* font-family: DaxlinePro; */
    font-style: normal;
    font-weight: bold;
    font-size: 0.8rem;
    line-height: 115%;
    /* identical to box height, or 28px */

    font-feature-settings: 'pnum' on, 'lnum' on;
}

.details-panel-avatar {
    height: 25%;
    object-fit: scale-down;
}

.details-panel-description {
    height: 120px;
    margin-top: 0;
    padding-top: 20px;
    box-sizing: border-box;
    pointer-events: auto;
    text-align: left;
    overflow-y: auto;

    /* read-only */
    user-select: none;

    overflow-y: auto;

    font-size: 0.7rem;
    font-weight: 600;
    color: #002543
}

.details-panel-realtime-title-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 8px;
}

.details-panel-realtime-title {
    /* font-family: DaxlinePro; */
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 115%;
}

.details-panel-realtime-subtitle {
    font-family: 'Inter';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 120%;
    /* or 19px */

    font-feature-settings: 'pnum' on, 'lnum' on;

    /* Systemair/UI/Hover Active */

    color: #047C71;
}

.details-panel-realtime-disconnected {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 48px;
    opacity: 0.25;
}

.details-panel-realtime-variables {   
    overflow-y: auto;
    pointer-events: auto;
    width: 100%;
    min-height: min-content;
    text-align: left;
    border-collapse: collapse;
    margin-top: 8px;
}

.details-panel-realtime-variables-name { 
    border-top: 1px solid #D9DDE1;
    border-bottom: 1px solid #D9DDE1;

    padding-top: 4px;
    padding-bottom: 4px;
}

.details-panel-realtime-variables-value { 
    border-top: 1px solid #D9DDE1;
    border-bottom: 1px solid #D9DDE1;
    text-align: right;
}

.details-panel-realtime-variables-unit {
    border-top: 1px solid #D9DDE1;
    border-bottom: 1px solid #D9DDE1;
    padding-left: 6px;
}

.details-panel-realtime-variables-indicator {
    border-top: 1px solid #D9DDE1;
    border-bottom: 1px solid #D9DDE1;
}


.details-panel-realtime-variables-indicator-bar {
    background: #037BC7;
    height: 4px;
    width: 64px;
    position: relative;
}

.details-panel-realtime-variables-indicator-bar[temperature] {
    background: linear-gradient(90deg, #00A7D8 0%, #2F789F 35.94%, #E46F59 100%);
}
        
.details-panel-realtime-variables-indicator-bar span {
    display: block;
    float: right;
    height: 100%;
    background: #EDEFF1;
}

</style>
