<template>
    <div v-show="ShowUserInfo" class="user-info">
        <!-- close button -->
        <div class="user-info-close" @click="closeUserInfo"><i class="fa-light fa-xmark"></i></div>
        
        <div class="user-info-title">
            Smart ways to save energy at Expo 2020
        </div>

        <div>
            <div class="user-info-subtitle">
                Ventilation is the new cooling
            </div>

            <div class="user-info-text">
                The Swedish pavilion’s AHUs contain <strong>sorption rotors</strong> that transfer sensible and latent heat. 
            </div>

            <div style="display: flex; flex-direction: row; justify-content: space-evenly; flex-wrap: nowrap; align-content: center; width: 100%;">
                <div style="width: 50%; margin-right: 24px;" class="user-info-text">
                    <strong>Sensible heat</strong> is what we can feel, measured by a thermometer. 
                </div>
                <div style="width: 50%; margin-left: 24px;" class="user-info-text">
                    <strong>Latent heat</strong> is the energy absorbed when water evaporates, and a higher room humidity has more latent heat. 
                </div>
            </div>

            <div class="user-info-text">
                It is thus crucial to transfer both, especially if cooling is required. Like this, we save cooling energy through less latent heat reduction.
            </div>
        </div>

        <div>
            <div class="user-info-subtitle" style="margin-top: 16px;">
                Fresh air provided on demand
            </div>
            
            <div class="user-info-text">
                Each AHU includes Systemair’s Access integrated control system. Units are <strong>demand-controlled</strong> – ensuring the best possible energy efficiency and fresh air supply through constant air volume controls.
            </div>
            <div class="user-info-text" style="margin-bottom: 0px !important;">
                BACnet integration ensures a seamless connection with the pavilion’s BMS system. A visual dashboard of all essential values, including flow charts, enables a quick overview of the current operating mode.
            </div>
        </div>

    </div>
</template>

<script>
import { mapGetters, mapMutations as mapSetters } from "vuex";

export default {

    data () 
    {
        return {
        }
    },

    mounted()
    {
        const UserInfo = document.querySelector(".user-info");
        if(this.IsMobile)
        {
            const Margin = 8;
            const PaddingX = 8;
            const PaddingY = 32;
            UserInfo.style.setProperty("top", "72px");
            UserInfo.style.setProperty("left", "0px");
            UserInfo.style.setProperty("width", `calc(100vw - ${2 * (Margin + PaddingX)}px)`);
            UserInfo.style.setProperty("max-height", `calc(100vh - (72px + ${2 * (Margin + PaddingY)}px))`);
            UserInfo.style.setProperty("margin", `${Margin}px`);
            UserInfo.style.setProperty("padding", `${PaddingY}px ${PaddingX}px`);
        }
        else
        {
            const PaddingX = 32;
            const PaddingY = 32;

            UserInfo.style.setProperty("top", "50%");
            UserInfo.style.setProperty("left", "50%");
            UserInfo.style.setProperty("transform", "translateX(-50%) translateY(-50%)");
            UserInfo.style.setProperty("padding", `${PaddingY}px ${PaddingX}px`);

            const UserInfoClose = document.querySelector(".user-info-close");
            UserInfoClose.style.setProperty("font-size", "32px");
            
            const UserInfoTitle = document.querySelector(".user-info-title");
            const UserInfoSubtitles = document.querySelectorAll(".user-info-subtitle");
            const UserInfoTexts = document.querySelectorAll(".user-info-text");

            UserInfoTitle.style.setProperty("font-size", '2.5rem');
            for(const subtitle of UserInfoSubtitles) 
            { 
                subtitle.style.setProperty("font-size", '1.8rem'); 
                subtitle.style.setProperty("margin-top", '24px'); 
                subtitle.style.setProperty("margin-bottom", '24px'); 
            }
            for(const text of UserInfoTexts) 
            { 
                text.style.setProperty("font-size", '1.2rem'); 
                text.style.setProperty("margin-top", '16px'); 
                text.style.setProperty("margin-bottom", '16px'); 
                text.style.setProperty("line-height", '28px'); 
            }
        }
    },

    methods: 
    { 
        ...mapSetters([ 
            "closeUserInfo" 
        ]) 
    },

    computed: 
    {
        ...mapGetters([ 
            "ShowUserInfo", 
            "IsMobile" 
        ])
    }
}
</script>

<style scoped>
.user-info {

    position: fixed;

    width: 800px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: flex-start;
    align-items: flex-start;

    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    line-height: 115%;
    /* or 41px */
    font-feature-settings: 'pnum' on, 'lnum' on;

    /* Systemair/UI/Text */
    color: #002543;
    background: #FFFFFF;

    box-shadow: 0px 16px 64px rgba(0, 37, 67, 0.2);

    overflow-y: auto;
    pointer-events: auto;
    z-index: 99;
}

.user-info-close {
    position: absolute;
    flex: none;
    top: 8px;
    right: 8px;
}

.user-info-title {
    text-align: left;
    font-weight: bold;
    font-size: 1.0rem;
    margin-bottom: 12px;

}

.user-info-subtitle {
    text-align: left;
    font-weight: 600;
    font-size: 0.8rem;
    margin-bottom: 8px;
}

.user-info-text {
    text-align: left;
    font-size: 0.7rem;
    margin-bottom: 8px;
}

</style>