<template>
    <div v-if="!IsMobile" class="weather-panel">
        <div class="weather-panel-title">Currently in Dubai:</div>
        <div class="weather-panel-entry">
            <i class="weather-panel-entry-icon fa-light fa-clock" />
            <span class="weather-panel-entry-label">{{ DubaiTime }}</span>
        </div>
        <div class="weather-panel-entry">
            <i class="weather-panel-entry-icon fa-light fa-temperature-half" />
            <span class="weather-panel-entry-label">{{ DubaiTemperatur }}</span>
            
        </div>
        <div class="weather-panel-entry">
            <i class="weather-panel-entry-icon fa-light fa-droplet-degree" />
            <span class="weather-panel-entry-label">{{ DubaiHumidity }}</span>
            
        </div>
        <div class="weather-panel-entry">
            <i class="weather-panel-entry-icon fa-light fa-wind" />
            <span class="weather-panel-entry-label">{{ DubaiAirQuality }}</span>
        </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {

    data() 
    {
        return {
            DubaiTime: this.UpdateDubaiTime(),
        }
    },

    mounted() { setInterval(() => { this.UpdateDubaiTime(); }, 1000); },

    computed: 
    {
        ...mapGetters([
            "IsMobile",
            "DubaiTemperatur",
            "DubaiHumidity",
            "DubaiAirQuality",
        ])
    },

    methods: 
    {
        UpdateDubaiTime() 
        {
            // Credits: https://www.techrepublic.com/article/convert-the-local-time-to-another-time-zone-with-this-javascript/
            function ComputeLocaltime(offset) 
            {
                const d = new Date();
                const utc = d.getTime() + (d.getTimezoneOffset() * 60000);
                const nd = new Date(utc + (3600000*offset));
                return nd.toTimeString().split(":").splice(0, 2).join(":");
            }

            this.DubaiTime = ComputeLocaltime('+4');
        },
    }
}
</script>

<style scoped>
.weather-panel {

    position: fixed;

    top: 16px;
    right: 16px;

    padding: 4px;
    display: flex;
    flex-direction : column;
    flex-wrap: nowrap;
    align-items: flex-start;

    font-family: 'Inter';
    font-style: normal;
    line-height: 120%;

    background: #FFFFFF;
}

.weather-panel-title {

    font-weight: bold;
    font-size: 0.8rem;
}

.weather-panel-entry {

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;

    margin-top: 4px;
    font-size: 0.7rem;
}

.weather-panel-entry-icon {
    width: 28px;
}

.weather-panel-entry-label {
}
</style>