











//@ts-nocheck
import { mapGetters, mapMutations as mapSetters } from "vuex";
export default {

    mounted() 
    {
        if(this.IsMobile)
        {
            const First = document.querySelector(".floor-selector-item[first]");
            First.style.setProperty("border-radius", "4px 0px 0px 4px");
            const Last = document.querySelector(".floor-selector-item[last]");
            Last.style.setProperty("border-radius", "0px 4px 4px 0px");
        }

        this.$refs.floor.onchange = (event) => { if (this.$store.getters.InitAnimationDone) this.OnSelectFloor(event.srcElement); }
       
        this.OnSelectFloor(this.$refs.DefaultFloor);
        this.setOnFloorSelectedCallback(( floor ) => {
            const htmlEL = this.$refs.floor.querySelector(`#radio-${ floor }`);
            this.OnSelectFloor(htmlEL, false);
          });
    },

    computed: 
    {
        ...mapGetters([
            "Floor", 
            "InitAnimationDone",
            "IsMobile"
        ]),

        FloorStyle()
        {
            return `
                flex-direction: ${this.IsMobile ? 'row' : 'column'};
                ${this.IsMobile ? 'bottom: 166px' : 'left: 16px'};
                ${this.IsMobile ? 'left: 50vw' : 'top: 50vh'};
                ${this.IsMobile ? 'transform: translateX(-50%)' : 'transform: translateY(-50%)'};
            `;
        },

        FloorItemStyle()
        {
            return `
                width: ${this.IsMobile ? '56' : '48'}px;
                height: ${this.IsMobile ? '48' : '56'}px;
            `;
        }
    },

    methods: 
    {
        ...mapSetters([
            "setFloor", "setOnFloorSelectedCallback"
        ]),

        OnSelectFloor(Input: HTMLElement, doSetFloor = true)
        {
            const floorElement = Input.parentElement;
            if(floorElement)
            {
                // remove previously selected floor
                this.$refs.floor.querySelectorAll(".floor-selector-item").forEach(Elem => { Elem.removeAttribute("checked"); })
                floorElement.setAttribute("checked", true);
                if( doSetFloor ) this.setFloor(floorElement.getAttribute("value"));
            }
        }
    }
}
