<template>
    <div class="eco-panel" @click="openUserInfo">
        <div class="eco-panel-user-info"><i class="fa-light fa-info-circle"></i></div>
        <div class="eco-panel-title">Sustainability achieved through latest cooling recovery, heap pump and air filtration technologies. Sorption rotors in AHUs reduce chiller load due to humidity transfer.</div>
        <div v-if="IsMobile">
            <div class="eco-panel-data-mobile">
                <div class="eco-panel-data-container-mobile">
                    <div class="eco-panel-data-container-inner-mobile">
                        <IOdometer :value="savedEnergy" class="eco-panel-data-value" format="(,ddd).d" />
                        <div>kWh<sub><!-- do not remove this sub, otherwise the text alignment will be wrong --></sub></div>
                    </div>
                    <div>Energy saved</div>
                </div>
                <div class="eco-panel-data-divider-mobile" />
                <div class="eco-panel-data-container-mobile">
                    <div class="eco-panel-data-container-inner-mobile">
                        <IOdometer :value="savedCo2" class="eco-panel-data-value" format="(,ddd).d" />
                        <div>kg CO<sub>2</sub></div>
                    </div>
                    <div>Emissions reduced</div>
                </div>
                <div class="eco-panel-data-divider-mobile" />
                <div class="eco-panel-data-container-mobile">
                    <div style="align-items: center;" class="eco-panel-data-container-inner-mobile">
                        <IOdometer :value="DailyFreshAirDelivered" class="eco-panel-data-value" format="(,ddd).d" />
                        <div>m<sup>3</sup>/day</div>
                    </div>
                    <div>Avg. Fresh Air delivered</div>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="eco-panel-data">
                <div class="eco-panel-data-inner">
                    <div class="eco-panel-data-container">
                        <IOdometer :value="savedEnergy" class="eco-panel-data-value" format="(,ddd).d" />
                        <div>kWh<sub><!-- do not remove this sub, otherwise the text alignment will be wrong --></sub></div>
                    </div>
                    <div>Energy saved</div>
                </div>
                <div class="eco-panel-data-divider" />
                <div class="eco-panel-data-inner">
                    <div class="eco-panel-data-container">
                        <IOdometer :value="savedCo2" class="eco-panel-data-value" format="(,ddd).d" />
                        <div>kg CO<sub>2</sub></div>
                    </div>
                    <div>Emissions reduced</div>
                </div>
                <div class="eco-panel-data-divider" />
                <div class="eco-panel-data-inner">
                    <div class="eco-panel-data-container">
                        <IOdometer :value="DailyFreshAirDelivered" class="eco-panel-data-value" format="(,ddd).d" />
                        <div>m<sup>3</sup>/day</div>
                    </div>
                    <div>Avg. Fresh Air delivered</div>
                </div>
            </div>
        </div>
        <div class="eco-panel-since">during {{ DaysSinceExpoStart }} days since start of Expo 2020 (1 October 2021)</div>
        <div v-if="IsMobile">
            <WeatherPanelSlim />
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations as mapSetters } from "vuex";

import WeatherPanelSlim from "@/components/WeatherPanelSlim.vue";

import IOdometer from 'vue-odometer';
import 'odometer/themes/odometer-theme-default.css';

export default {

    components: {
        IOdometer,
        WeatherPanelSlim
    },

    data() {
        return {
        }
    },

    mounted() 
    {
        if(this.IsMobile)
        {
            const EcoPanel = document.querySelector(".eco-panel");
            EcoPanel.style.setProperty("width", "100%");
            EcoPanel.style.setProperty("bottom", "0");
            EcoPanel.style.setProperty("padding", "0");

            const EcoPanelTitle = document.querySelector(".eco-panel-title");
            EcoPanelTitle.style.setProperty("padding-left", "8px");
            EcoPanelTitle.style.setProperty("padding-right", "24px");
            EcoPanelTitle.style.setProperty("text-align", "center");
            EcoPanelTitle.style.setProperty("font-size", "0.7rem");

            const UserInfo = document.querySelector(".eco-panel-user-info");
            UserInfo.style.setProperty("font-size", "1.0rem");
        }
    },

    computed: 
    {
        cssVars() { return { '--font-size': this.$store.state.SelectedUnit.Serial ? "none" : "auto" } },

        ...mapGetters([
            "IsMobile",
            "DaysSinceExpoStart",
            "savedEnergy",
            "savedCo2",
            "DailyFreshAirDelivered"
        ])
    },

    methods: 
    {
        ...mapSetters([
            "openUserInfo"
        ])
    }
}
</script>

<style scoped>

.eco-panel {

    position: fixed;

    width: 600px;
    height: 170px;

    left: 50%;
    transform: translateX(-50%);
    bottom: 16px;

    padding: 0px 32px;
    
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: space-between;

    /* Systemair/UI/B&W/000 */
    background: #FFFFFF;

    /* Small/UI/Label/Default */
    font-family: 'Inter';
    font-style: normal;
    font-weight: normal;
    font-size: 0.8rem;

    overflow: hidden;
    pointer-events: auto;
}

.eco-panel-user-info {
    position: absolute;
    top: 4px;
    right: 4px;
    text-align: right;
    font-size: 1.2rem;

    color: #004985;
}

.eco-panel-title {
    
    font-feature-settings: 'pnum' on, 'lnum' on;

    /* Systemair/UI/Text */
    color: #002543;

    /* Inside Auto Layout */
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin: 8px 0px;
}

.eco-panel-data {

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    padding: 0px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: bold;
    font-size: 0.8rem;
    line-height: 115%;

}

.eco-panel-data-container {
    display: flex; 
    flex-direction: row; 
    justify-content: flex-start;
    align-items: baseline;
}

.eco-panel-data-divider {
    width: 1px;
    height: 42px;
    margin: 0px 16px;

    /* Systemair/UI/Neutral/200 */
    background: #D9DDE1;
}

.eco-panel-data-divider-mobile {
    min-width: 1px;
    height: 42px;

    display: flex; 
    flex-direction: row; 
    align-items: center;
    justify-content: center;

    /* Systemair/UI/Neutral/200 */
    background: #D9DDE1;
}

.eco-panel-data-mobile {

    width: 100%;

    font-family: 'Inter';
    font-style: normal;
    font-weight: bold;
    font-size: 0.8rem;
    line-height: 115%;

    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    text-align: center;

    scroll-behavior: smooth;
    /* scroll-snap-type: x mandatory; */

    pointer-events: auto;
    touch-action: auto;
    overflow-y: hidden;
    overflow-x: auto;
}

.eco-panel-data-container-mobile {

    height: 55px;
    /* min-width: 100%; */

    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    text-align: center;

    scroll-snap-align: center;
    /* scroll-snap-stop: always mandatory; */
}

.eco-panel-data-container-inner-mobile {
    width: 180px;
    display: flex; 
    flex-direction: row; 
    align-items: flex-end;
    justify-content: center;
}

.eco-panel-data-value {
    font-size: 1.2rem;
    margin-right: 4px;
}

.eco-panel-since {

    margin: 8px 0px;
    line-height: 120%;
    font-feature-settings: 'pnum' on, 'lnum' on;
    /* Systemair/UI/Text */
    color: #002543;
}

.eco-panel-data-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
}

</style>